import { Formik } from 'formik';
import { Column, Flex } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  AddProductModal,
  DatePicker,
  FieldRow,
  FormCard,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  RequestHandler,
  TextField,
  TextLink,
  Typography,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useNotificationsContext } from '../../../context';
import {
  FIND_PURCHASE_FORWARDINGS_QUERY,
  FIND_PURCHASE_PARSELS_QUERY,
} from '../../../graphql';
import { useGetPurchaseQuery, useParams, usePurchaseMutation } from '../../../hooks';
import { addPurchaseSchema } from '../../../services/validation';
import { getVolumeWeightProduct, getWeightProduct } from '../../../utils';

const Purchase = () => {
  const intl = useIntl();
  const params = useParams();

  const { showNotification } = useNotificationsContext();

  const { data, ...queryProps } = useGetPurchaseQuery();

  const [addPurchase, { ...addPurchaseProps }] = usePurchaseMutation();

  const purchase = data?.purchase;

  const handleFormSubmit = (values) => {
    if (!values.product) {
      showNotification({
        type: 'error',
        title: 'errors.default',
        message: 'product.required',
      });
      return;
    }

    addPurchase({
      variables: {
        input: {
          id: +params?.id || null,
          product: +values?.product?.id,
          price: values.price ? String(values.price) : null,
          deliveryPrice: values.deliveryPrice ? String(values.deliveryPrice) : null,
          parcelId: +values?.parcel?.id || null,
          forwardingId: +values?.forwarding?.id || null,
          purchaseTime: values.purchaseTime ? new Date(values.purchaseTime) : null,
          quantity: +values.quantity,
        },
      },
    });
  };

  const isError = queryProps.error || addPurchaseProps.error;

  const initialValues = {
    product: purchase ? purchase?.product : '',
    price: purchase?.price || '',
    deliveryPrice: purchase?.deliveryPrice || '',
    parcel: purchase ? purchase?.parcel : '',
    forwarding: purchase ? purchase?.forwarding : '',
    purchaseTime: purchase?.purchaseTime ? new Date(+purchase?.purchaseTime) : '',
    quantity: purchase ? String(purchase?.quantity) : '',
    customer: [],
  };

  return (
    <RequestHandler
      loading={queryProps.loading}
      error={isError}
      backgroundLoading={addPurchaseProps.loading}
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={addPurchaseSchema}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Layout
                onActionButtonPress={handleSubmit}
                actionButtonType='save'
                actionButtonIsLoading={addPurchaseProps.loading}
              >
                <Flex flexGrow={1} wrap='wrap'>
                  <FormCard>
                    <Typography
                      intlId='app.product'
                      variant='heading'
                      mb={IS_MOBILE_SCREEN ? '16px' : '41px'}
                    />
                    <Flex
                      justify='space-between'
                      align='center'
                      direction={IS_MOBILE_SCREEN ? 'column' : 'row'}
                      mb={IS_MOBILE_SCREEN ? 26 : 36}
                    >
                      <TextLink link={`/warehouse/product/${values.product?.id}`}>
                        {values?.product?.name || ''}
                      </TextLink>
                      <AddProductModal
                        addedProducts={[values?.product]}
                        buttonText={values?.product ? 'app.change' : 'app.addProduct'}
                        onAddProduct={(product) => {
                          setFieldValue('product', product);
                        }}
                      />
                    </Flex>
                    <FieldRow
                      withNegativeBottomMargin={false}
                      flexDirection={IS_MOBILE_SCREEN ? 'column' : 'row'}
                    >
                      <Column w={IS_MOBILE_SCREEN ? 'auto' : 0}>
                        <DatePicker name='purchaseTime' label='app.date' mb={'0px'} />
                      </Column>
                      <Column />
                    </FieldRow>
                  </FormCard>
                  {IS_MOBILE_SCREEN && (
                    <FormCard>
                      <Typography intlId='app.delivery' variant='heading' />
                      <FieldRow>
                        <Column>
                          <QuerySelectWithSearch
                            label={'app.trackingParcelCode'}
                            fieldName={'parcel'}
                            graphql={FIND_PURCHASE_PARSELS_QUERY}
                            rowsInResponse={true}
                            graphqlName='purchaseParcels'
                            labelProperty={'trackingCode'}
                            valueProperty={['id', 'trackingCode']}
                            pl={0}
                            pr={0}
                            mb={0}
                          />
                        </Column>
                        <Column>
                          <QuerySelectWithSearch
                            label={'app.trackingForwardingCode'}
                            fieldName={'forwarding'}
                            graphql={FIND_PURCHASE_FORWARDINGS_QUERY}
                            rowsInResponse={true}
                            graphqlName='purchaseForwardings'
                            labelProperty={'trackingCode'}
                            valueProperty={['id', 'trackingCode']}
                            pl={0}
                            pr={0}
                            mb={0}
                          />
                        </Column>
                      </FieldRow>
                    </FormCard>
                  )}
                  <FormCard>
                    <Typography intlId='app.finance' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='price' label='app.costUSD' type='number' />
                      <TextField name='quantity' label='app.amount' type='number' />
                      <TextField
                        name='weight'
                        label='app.weightKg'
                        isDisabled
                        value={getWeightProduct(values)}
                      />
                    </FieldRow>
                    <FieldRow position='flex-end'>
                      <TextField
                        name='deliveryPrice'
                        label='app.costPriceUSD'
                        type='number'
                      />
                      <TextField
                        name='volumeWeight'
                        value={getVolumeWeightProduct(values, intl)}
                        label='app.volumeWeight'
                        isDisabled
                      />
                    </FieldRow>
                  </FormCard>
                  {IS_MOBILE_SCREEN || (
                    <FormCard>
                      <Typography intlId='app.delivery' variant='heading' />
                      <FieldRow>
                        <QuerySelectWithSearch
                          label={'app.trackingParcelCode'}
                          fieldName={'parcel'}
                          graphql={FIND_PURCHASE_PARSELS_QUERY}
                          rowsInResponse={true}
                          graphqlName='purchaseParcels'
                          labelProperty={'trackingCode'}
                          valueProperty={['id', 'trackingCode']}
                        />
                        <QuerySelectWithSearch
                          label={'app.trackingForwardingCode'}
                          fieldName={'forwarding'}
                          graphql={FIND_PURCHASE_FORWARDINGS_QUERY}
                          rowsInResponse={true}
                          graphqlName='purchaseForwardings'
                          labelProperty={'trackingCode'}
                          valueProperty={['id', 'trackingCode']}
                        />
                      </FieldRow>
                    </FormCard>
                  )}
                </Flex>
              </Layout>
            );
          }}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Purchase;
