/* eslint-disable react-native/no-inline-styles */
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { Select } from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';

const OrderStatus = ({ statuses, users, sources }) => {
  const intl = useIntl();

  return (
    <>
      <Title style={stylesGlobal.title}>
        {intl.formatMessage({ id: 'order.status' })}
      </Title>
      <View style={stylesGlobal.formWrapper}>
        <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
          <Select
            name='status'
            label='order.status'
            options={transformSelectOptions({
              data: statuses,
              label: 'name',
            })}
          />
        </View>
        <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
          <Select
            name='user'
            label='app.manager'
            options={transformSelectOptions({
              data: users,
              label: ['firstName', 'lastName'],
            })}
          />
        </View>
        <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
          <Select
            name='source'
            label='app.source'
            options={transformSelectOptions({
              data: sources,
              label: 'name',
            })}
          />
        </View>
      </View>
    </>
  );
};

OrderStatus.propTypes = {
  statuses: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
};

export default OrderStatus;
