import { StyleSheet } from 'react-native';

import { colors } from '../../theme';

export const styles = StyleSheet.create({
  item: {
    alignItems: 'center',
    borderBottomColor: colors.primary.default,
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 8,
    paddingTop: 16,
  },
  text: {
    fontSize: 16,
  },
  wrapper: {
    flexDirection: 'row',
  },
});
