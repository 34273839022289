import queryString from 'query-string';

import { convertToInt } from '../utils';
import useHistory from './useHistory';
import useRoute from './useRoute';

const useFilter = () => {
  const { search, pathname } = useRoute();
  const history = useHistory();

  return {
    filters: convertToInt(queryString.parse(search)) || {},
    setFilters: (values) =>
      history.push(
        pathname +
          '?' +
          queryString.stringify({ ...queryString.parse(search), ...values }),
      ),
    clearFilters: () => history.push(pathname),
  };
};

export default useFilter;
