import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useNotificationsContext, useSearchContext } from '../../context';
import { useFindProducts } from '../../hooks';
import { getCurrencyById } from '../../utils';
import {
  AddModal,
  Button,
  DesktopTable,
  ImageView,
  ModalButton,
  ProductCard,
  RequestHandler,
  TextLink,
  Typography,
} from '../index';

export const AddProductModal = ({
  onAddProduct,
  source,
  currencyRatio,
  buttonText = 'app.select',
  errorText,
  addedProducts = [],
  hasError = false,
  isMultiSelect = false,
  withQuantity = false,
  withPrice = false,
  inOrder = false,
}) => {
  const { searchValue, changeSearchValue } = useSearchContext();

  const { showNotification } = useNotificationsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: productsData, ...queryProps } = useFindProducts(
    searchValue || '',
    +source || 0,
  );

  const addedProductsIds = addedProducts?.map((el) => el?.id);

  const isProductAdded = (id) => addedProductsIds?.includes(id);

  const onAddProductHandler = (product) => {
    onAddProduct(product);
    !isMultiSelect && setIsModalOpen(false);
  };

  const handlerButtonPress = () => {
    if (hasError) {
      showNotification({
        message: errorText,
        type: 'error',
        title: 'errors.default',
      });
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <Box>
      <Flex justifyContent={'flex-end'}>
        <Button
          intlId={buttonText}
          onPress={handlerButtonPress}
          width={inOrder ? '120px' : 'auto'}
          minWidth={'120px'}
          size='sm'
        />
      </Flex>
      <AddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        queryProps={queryProps}
        title='app.addProduct'
      >
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable editable={!isMultiSelect} inModal>
              <DesktopTable.Head>
                <DesktopTable.Title width={50} />
                <DesktopTable.Title intlId={'app.title'} textAlign={'left'} />
                <DesktopTable.Title
                  intlId={withQuantity ? 'app.inStock' : ' '}
                  textAlign={'center'}
                />
                <DesktopTable.Title
                  intlId={withPrice ? 'app.price' : ' '}
                  textAlign={'right'}
                />
                <DesktopTable.Title width={10} />
                <DesktopTable.Title width={80} />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {productsData?.products?.map((item, index) => (
                  <DesktopTable.Row key={index}>
                    <DesktopTable.Cell width={50}>
                      <ImageView uri={item?.photo?.path} />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'left'}>
                      <Box>
                        <TextLink link={`/warehouse/product/${item?.id}`}>
                          {item?.name}
                        </TextLink>
                        <Typography fontSize='xs'>{item?.sku}</Typography>
                      </Box>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'center'}>
                      <Typography>
                        {withQuantity &&
                          (source ? item?.source?.availability : item?.quantity)}
                      </Typography>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'right'}>
                      <Typography>
                        {withPrice &&
                          (source ? (
                            <>
                              <Typography mr={1}>
                                {item?.source?.price?.toFixed(2)}
                              </Typography>
                              <Typography intlId={getCurrencyById(+currencyRatio?.id)} />
                            </>
                          ) : (
                            <>
                              <Typography mr={1}>{item?.price}</Typography>
                              <Typography
                                intlId={getCurrencyById(+item?.priceCurrencyId)}
                              />
                            </>
                          ))}
                      </Typography>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography />
                    </DesktopTable.Cell>
                    <ModalButton
                      isAvailable={
                        source ? item?.source?.availability !== 0 : item?.quantity !== 0
                      }
                      withQuantity={withQuantity}
                      addedItemsIds={addedProductsIds}
                      handleSet={onAddProductHandler}
                      item={item}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <>
              {productsData?.products?.map((item, index) => (
                <ProductCard
                  key={index}
                  product={item}
                  isProductAdded={isProductAdded(item?.id)}
                  isNotAvailable={
                    (source ? item?.source?.availability === 0 : item?.quantity === 0) &&
                    withQuantity
                  }
                  onPress={onAddProductHandler}
                  inOrder={inOrder}
                />
              ))}
            </>
          )}
        </RequestHandler>
      </AddModal>
    </Box>
  );
};

AddProductModal.propTypes = {
  buttonText: PropTypes.string,
  errorText: PropTypes.string,
  onAddProduct: PropTypes.func.isRequired,
  addedProducts: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool,
  withQuantity: PropTypes.bool,
  withPrice: PropTypes.bool,
  hasError: PropTypes.bool,
  inOrder: PropTypes.bool,
  source: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencyRatio: PropTypes.object,
};
