import {
  BookkeepingIcon,
  DocumentationIcon,
  NewslettersIcon,
  PurchaseIcon,
  SalesIcon,
  SettingsIcon,
  StatisticIcon,
  WarehouseIcon,
} from '../../components/Icons';

export default [
  {
    name: 'sales',
    label: 'sales/home',
    icon: SalesIcon,
    scopes: ['Section:Sales:Access'],
    defaultRole: 'Salesman',
  },
  {
    name: 'bookkeeping',
    label: 'bookkeeping/home',
    icon: BookkeepingIcon,
    scopes: ['Section:Bookkeeping:Access'],
    defaultRole: 'Bookkeeper',
  },
  {
    name: 'warehouse',
    label: 'warehouse/home',
    icon: WarehouseIcon,
    scopes: ['Section:Warehouse:Access'],
    defaultRole: 'Stockman',
  },
  {
    name: 'statistic',
    label: 'statistic/home',
    icon: StatisticIcon,
    scopes: ['Section:Statistic:Access'],
  },
  {
    name: 'purchase',
    label: 'purchase/purchases',
    icon: PurchaseIcon,
    scopes: ['Section:Purchase:Access'],
    defaultRole: 'PurchasingManager',
  },
  {
    name: 'documentation',
    label: 'documentation/home',
    icon: DocumentationIcon,
    scopes: ['Section:Documentation:Access'],
  },
  {
    name: 'newsletters',
    label: 'newsletters/home',
    icon: NewslettersIcon,
    scopes: ['Section:Newsletters:Access'],
  },
  {
    name: 'settings',
    label: 'settings/home',
    icon: SettingsIcon,
    scopes: ['Section:Settings:Access'],
    defaultRole: 'Administrator',
  },
];
