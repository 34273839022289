import { Flex } from 'native-base';
import React from 'react';

import {
  Avatar,
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN, LIMIT_USERS } from '../../../constants';
import { useSearchContext } from '../../../context';
import { useGetUsersQuery, useHistory } from '../../../hooks';
import useFilter from '../../../hooks/useFilter';
import { getFormattedDate, getName } from '../../../utils';
import UserMobileItem from './UserMobileItem';

const COLUMNS = [
  { intlId: 'app.name' },
  { intlId: 'app.registration' },
  { intlId: 'app.email' },
  { intlId: 'app.phone' },
  { intlId: 'app.role' },
];

const Users = () => {
  const navigation = useHistory();

  const { data, fetchMore, ...queryProps } = useGetUsersQuery();

  const { debouncedSearchValue } = useSearchContext();

  const { filters } = useFilter();

  // const handleEditButtonClick = (id) => {
  //   navigation.push('/settings/user/' + id);
  // };

  const handleClickAddButton = () => {
    navigation.push('/settings/user/');
  };

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        offset: data?.filteredUsers?.length,
        limit: LIMIT_USERS,
        order: filters?.direction || 'desc',
        orderBy: filters?.field || 'datetime',
        filters: {
          name: filters?.name || '',
          dateFrom: filters?.dateFrom || '',
          dateTo: filters?.dateTo || '',
          email: filters?.email || '',
          phone: filters?.phone || '',
          roles: filters?.role || [],
        },
        search: debouncedSearchValue,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          filteredUsers: [...prev?.filteredUsers, ...fetchMoreResult?.filteredUsers],
        };
      },
    });
  };

  const getRoles = (roles) => {
    return roles.map((role) => role.name).join(', ');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...queryProps}>
          <DesktopTable fetchMoreData={fetchMoreData} editable={false}>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.filteredUsers?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  // onEditButtonClick={() => handleEditButtonClick(item?.id)}
                >
                  <DesktopTable.Cell>
                    <Flex>
                      <Avatar
                        uri={item.avatar}
                        firstName={item.firstName}
                        lastName={item.lastName}
                      />
                      <Typography ml='lg'>
                        {getName(item.firstName, item.lastName)}
                      </Typography>
                    </Flex>
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getFormattedDate(item.createdAt)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{item.email}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.phone}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getRoles(item.roles)}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout isScrollable={false}>
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.filteredUsers}
          fetchMoreData={fetchMoreData}
          renderItem={(item) => (
            <UserMobileItem
              // onItemPress={handleEditButtonClick}
              id={item.id}
              name={getName(item.firstName, item.lastName)}
              avatar={
                <Avatar
                  uri={item.avatar}
                  firstName={item.firstName}
                  lastName={item.lastName}
                />
              }
              roles={getRoles(item.roles)}
              email={item.email}
              phone={item.phone}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default Users;
