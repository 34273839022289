import { Formik } from 'formik';
import React from 'react';
import { Card } from 'react-native-paper';

import { FormRow, Layout, RequestHandler } from '../../../components';
import { useNotificationsContext } from '../../../context';
import stylesGlobal from '../../../globalStyles';
import { useOrderMutation, useOrderQuery } from '../../../hooks';
import { orderSchema } from '../../../services/validation';
import {
  DeliveryProps,
  DescriptionProps,
  OrderCustomer,
  OrderItems,
  OrderStatus,
  PaymentProps,
} from './Properties';

const Order = () => {
  const { data, ...orderQueryProps } = useOrderQuery();

  const [mutation, { loading }] = useOrderMutation(data?.order?.id);

  const { showNotification } = useNotificationsContext();

  const order = data?.order;

  const customer = order?.customer;

  const contacts = customer?.phones
    ?.map((phone) => phone?.phone)
    .concat(customer?.emails?.map((email) => email?.email)) || [''];

  const onSubmit = (values) => {
    if (!values.orderItems.length) {
      showNotification({
        type: 'error',
        title: 'errors.default',
        message: 'products.required',
      });
      return;
    }

    const orderItems = values.orderItems?.map(
      ({ id: itemId, product, pricePerPcs, quantity, availability }) => ({
        id: itemId ? +itemId : undefined,
        allowedConditions: availability?.allowedConditions.map(({ id }) => ({
          id: +id,
        })),
        pricePerPcs: +pricePerPcs,
        product: +product.id,
        quantity: +quantity,
      }),
    );

    const { id, firstName, lastName, middleName, contacts } = values.customer;
    const { city, ttn, address, comment } = values.deliveryInformation;

    const phones = contacts?.filter((contact) => contact.match(/^\+\d{12}$/));

    const emails = contacts?.filter((contact) =>
      contact.match(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/),
    );

    const orderInput = {
      id: values.id ? +values.id : undefined,
      user: +values.user,
      description: values.description,
      source: +values.source,
      status: +values.status,
      paymentType: +values.paymentType,
      paymentStatus: +values.paymentStatus,
      shippingType: +values.shippingType,
      deliveryInformation: {
        city: city.id,
        ttn,
        address,
        comment,
      },
      novaPoshtaShipping: {
        ...values.novaPoshtaShipping,
        street:
          values.novaPoshtaShipping.serviceType === 'WarehouseDoors'
            ? values.novaPoshtaShipping.streetRef
            : undefined,
      },
      orderItems,
      customer: {
        id: +id,
        firstName,
        lastName,
        middleName,
        phones,
        emails,
      },
    };

    mutation({
      variables: {
        input: orderInput,
      },
    });
  };

  const initialValues = {
    id: +order?.id || undefined,
    status: order?.status?.id || '',
    description: order?.description || '',
    user: order?.user?.id || '',
    source: order?.source?.id || '',
    paymentType: order?.paymentType?.id || '',
    paymentStatus: order?.paymentStatus?.id || '',
    orderItems: order?.orderItems,
    shippingType: order?.shippingType?.id || '',
    deliveryInformation: {
      city: order?.deliveryInformation?.city || '',
      address: order?.deliveryInformation?.address || '',
      comment: order?.deliveryInformation?.comment || '',
      ttn: order?.deliveryInformation?.ttn || '',
    },
    novaPoshtaShipping: {
      serviceType: order?.novaPoshtaShipping?.serviceType || 'WarehouseWarehouse',
      cityRef: order?.novaPoshtaShipping?.city?.ref || '',
      streetRef: order?.novaPoshtaShipping?.street || '',
      addressRef: order?.novaPoshtaShipping?.street
        ? ''
        : order?.novaPoshtaShipping?.addressRef || '',
      payerType: order?.novaPoshtaShipping?.payerType || '',
      buildingNumber: order?.novaPoshtaShipping?.buildingNumber || '',
      flatNumber: order?.novaPoshtaShipping?.flatNumber || '',
    },
    customer: {
      id: +customer?.id || null,
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      middleName: customer?.middleName || '',
      contacts: contacts || [],
    },
  };

  return (
    <RequestHandler {...orderQueryProps}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={orderSchema}
      >
        {({ values, handleSubmit }) => (
          <Layout
            onActionButtonPress={handleSubmit}
            actionButtonIsLoading={loading}
            actionButtonType='save'
          >
            <FormRow>
              <FormRow.Left>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <OrderStatus
                      statuses={data?.statuses || []}
                      users={data?.users || []}
                      sources={data?.sources || []}
                    />
                  </Card.Content>
                </Card>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <OrderItems statuses={data?.statuses} source={+values.source} />
                  </Card.Content>
                </Card>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <OrderCustomer />
                  </Card.Content>
                </Card>
              </FormRow.Left>
              <FormRow.Right>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <PaymentProps
                      paymentTypes={data?.paymentTypes || []}
                      paymentStatuses={data?.paymentStatuses || []}
                    />
                  </Card.Content>
                </Card>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <DeliveryProps
                      selectedShippingType={values?.shippingType}
                      shippingTypes={data?.shippingTypes || []}
                    />
                  </Card.Content>
                </Card>
                <Card style={stylesGlobal.form}>
                  <Card.Content>
                    <DescriptionProps name='description' />
                  </Card.Content>
                </Card>
              </FormRow.Right>
            </FormRow>
          </Layout>
        )}
      </Formik>
    </RequestHandler>
  );
};

export default Order;
