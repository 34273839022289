import { MaterialIcons } from '@expo/vector-icons';
import { ReactNativeFile } from 'apollo-upload-client';
import { ImageEditor } from 'expo-image-editor';
import * as ImagePicker from 'expo-image-picker';
import { Flex, Icon, Spinner, View } from 'native-base';
import React, { useRef, useState } from 'react';
import { Image } from 'react-native';

import { BoxWrapper, Button, Typography } from '../../../../../components';
import { API_HOST } from '../../../../../constants';
import stylesGlobal from '../../../../../globalStyles';
import { useAddFormattedPhoto, useAddPhotoMutation } from '../../../../../hooks';
import { colors } from '../../../../../theme';
import { styles } from './styles';

const UploadImageMobile = (photoFormats) => {
  const photo = useRef({ id: '', url: '' });
  const pathCropPhoto = photoFormats?.photoFormats?.formats?.find(
    (el) => el.format === 'smallCrop',
  ).pathWithTime
    ? `${API_HOST}/${
        photoFormats?.photoFormats?.formats?.find((el) => el.format === 'smallCrop')
          .pathWithTime
      }`
    : '';

  const pathPhoto = photoFormats?.photoFormats?.path
    ? `${API_HOST}/${photoFormats?.photoFormats?.path}`
    : '';

  const [addPhoto, { loading }] = useAddPhotoMutation(photo);
  const [addFormatedPhoto] = useAddFormattedPhoto(photo);

  const [imageUri, setImageUri] = useState(pathPhoto);
  const [croppedImageUri, setCroppedImageUri] = useState(pathCropPhoto);
  const [editorVisible, setEditorVisible] = useState(true);

  const generateRNFile = (uri) => {
    const extension = uri?.substring(uri?.lastIndexOf('.') + 1);
    return new ReactNativeFile({
      uri: uri,
      name: 'avatar.jpg',
      type: `image/${extension}`,
    });
  };

  const handlePickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [1, 1],
      quality: 1,
    });
    let file = null;
    if (!result.cancelled) {
      file = generateRNFile(result.uri);
      setImageUri(result.uri);
    }
    await addPhoto({
      variables: {
        input: { file },
      },
    });
    setEditorVisible(true);
  };

  const onCropComplete = (result) => {
    addFormatedPhoto({
      variables: {
        input: {
          photoId: photo.current.id,
          format: 'smallCrop',
          top: 0,
          left: 0,
          width: result.width,
          height: result.height,
        },
      },
    });
  };

  return (
    <View>
      <BoxWrapper style={stylesGlobal.form} p={4}>
        <Typography mb={6} intlId='app.productPhoto' />

        {!imageUri && !loading && (
          <>
            <Image
              source={require('../../../../../assets/photo.png')}
              style={styles.image}
            />
            <Flex justify={'flex-end'} paddingTop={4}>
              <Button
                intlId='app.uploadFile'
                w={'55%'}
                size='sm'
                onPress={handlePickImage}
              />
            </Flex>
          </>
        )}
        {loading && (
          <View>
            <Flex style={styles.download} paddingY='lg'>
              <Spinner size='lg' />
              <Typography
                style={styles.text}
                intlId='app.uploadingPhoto'
                variant='heading'
              />
            </Flex>
            <Flex justify={'flex-end'} paddingTop={4}>
              <Button intlId='app.uploadFile' w={'55%'} size='sm' />
            </Flex>
          </View>
        )}
        {imageUri && !croppedImageUri && (
          <>
            <ImageEditor
              visible={editorVisible}
              onCloseEditor={() => setEditorVisible(false)}
              imageUri={imageUri}
              fixedCropAspectRatio={1 / 1}
              lockAspectRatio={true}
              minimumCropDimensions={{
                width: 50,
                height: 50,
              }}
              onEditingComplete={(result) => {
                onCropComplete(result);
                setCroppedImageUri(result.uri);
              }}
              mode='crop-only'
            />
            <Image source={{ uri: imageUri }} style={styles.image} />
            <Flex justify={'space-between'} paddingTop={4}>
              <Button
                size='sm'
                intlId='app.remove'
                backgroundColor={colors.danger}
                w={'40%'}
                onPress={() => setImageUri('')}
              />
              <Button
                intlId='app.uploadFile'
                w={'55%'}
                size='sm'
                onPress={handlePickImage}
              />
            </Flex>
          </>
        )}
        {imageUri && croppedImageUri && (
          <>
            <Image source={{ uri: croppedImageUri }} style={styles.image} />
            <Icon
              position={'absolute'}
              top={'61px'}
              right={'16px'}
              name='edit'
              as={MaterialIcons}
              size={26}
              color={colors.primary.default}
              bgColor={colors.white}
              onPress={() => {
                setCroppedImageUri(null);
                setEditorVisible(true);
              }}
            />
            <Flex justify={'space-between'} paddingTop={4}>
              <Button
                size='sm'
                intlId='app.remove'
                backgroundColor={colors.danger}
                w={'40%'}
                onPress={() => {
                  setCroppedImageUri('');
                  setImageUri('');
                  photo.current.id = '';
                  photo.current.url = '';
                }}
              />
              <Button
                intlId='app.uploadFile'
                w={'55%'}
                size='sm'
                onPress={handlePickImage}
              />
            </Flex>
          </>
        )}
      </BoxWrapper>
    </View>
  );
};

export default UploadImageMobile;
