import React from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import { Layout } from '../../../components';

const PaymentMethods = () => {
  const intl = useIntl();
  return (
    <Layout>
      <View>
        <Text>{intl.formatMessage({ id: 'page.paymentMethodsCoomingSoon' })}</Text>
      </View>
    </Layout>
  );
};

export default PaymentMethods;
