import { getLabel } from './getLabel';
import { getValue } from './getValue';

export const transformSelectOptions = ({ data, value = 'id', label = 'name' }) => {
  if (!data || data?.length === 0) return [];

  if (Array.isArray(data)) {
    return data?.map((item) => ({
      value: getValue(item, value),
      label: getLabel(item, label),
    }));
  }
};
