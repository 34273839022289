import { MaterialIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import { Center, Icon } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import {
  useExchangeMutation,
  useGetBankAccounts,
  useGetExchangeQuery,
  useParams,
} from '../../../hooks';
import { exchangeSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';

const Exchange = () => {
  const params = useParams();
  const { data: bankAccounts, ...bankAccountsQueryProps } = useGetBankAccounts();
  const { data, ...queryProps } = useGetExchangeQuery();
  const [exchangeMutation, { loading }] = useExchangeMutation();

  const handleFormSubmit = (values) => {
    exchangeMutation({
      variables: {
        input: {
          date: values.date,
          comment: values.comment,
          fromAmount: values.fromAmount,
          fromBankAccountId: Number(values.fromBankAccountId),
          toAmount: values.toAmount,
          toBankAccountId: Number(values.toBankAccountId),
          ...(params?.id ? { id: Number(data?.exchange?.id) } : {}),
        },
      },
    });
  };

  const initialValues = {
    date: data?.exchange?.date || new Date(),
    fromAmount: data?.exchange?.fromAmount || '',
    fromBankAccountId: data?.exchange?.fromBankAccountId?.toString() || '',
    toBankAccountId: data?.exchange?.toBankAccountId?.toString() || '',
    toAmount: data?.exchange?.toAmount || '',
    comment: data?.exchange?.comment || '',
  };

  return (
    <RequestHandler
      loading={queryProps.loading || bankAccountsQueryProps.loading}
      error={queryProps.error || bankAccountsQueryProps.error}
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={exchangeSchema}
        >
          {({ handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.exchange' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <DatePicker name='date' label='app.date' />
                    </FieldRow>
                    <FieldRow withNegativeBottomMargin={false}>
                      <Select
                        name='fromBankAccountId'
                        label='app.fromCheck'
                        options={transformSelectOptions({
                          data: bankAccounts?.bankAccounts,
                          label: 'title',
                        })}
                      />
                      <Select
                        name='toBankAccountId'
                        label='app.toCheck'
                        options={transformSelectOptions({
                          data: bankAccounts?.bankAccounts,
                          label: 'title',
                        })}
                      />
                    </FieldRow>
                    <Center>
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              IS_DESKTOP_SCREEN ? 'keyboard-arrow-right' : 'expand-more'
                            }
                          />
                        }
                        size={6}
                        color='#965A85'
                        mb='10px'
                      />
                    </Center>
                    <FieldRow>
                      <TextField
                        type='number'
                        name='fromAmount'
                        label='app.originalAmount'
                      />
                      <TextField type='number' name='toAmount' label='app.targetAmount' />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='app.comment' variant='heading' />
                    <FieldRow>
                      <TextField name='comment' placeholderIntlId='app.enterComment' />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Exchange;
