import { FieldArray, Formik } from 'formik';
import { Box } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  QuerySelectWithSearch,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { FIND_PURCHASE_FORWARDINGS_QUERY } from '../../../graphql';
import {
  useGetPurchaseParcelQuery,
  useGetPurchaseParcelStatusesQuery,
  useGetPurchaseSourcesQuery,
  useParams,
  useSaveParcel,
} from '../../../hooks';
import { addParcelSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';
import ParcelStructure from './Structure/ParcelStructure';

const Parcel = () => {
  const [onSaveParcel, isSaveParcelLoading] = useSaveParcel();

  const { id } = useParams();

  const { data: purchaseParcelData, loading: purchaseLoading } =
    useGetPurchaseParcelQuery(id);

  const { data: purchaseSources, loading: sourcesLoading } = useGetPurchaseSourcesQuery();

  const { data: statuses, loading: statusesLoading } =
    useGetPurchaseParcelStatusesQuery();

  const loading = [sourcesLoading, statusesLoading, purchaseLoading].some((load) => load);

  const getSourceOptions = () => {
    return transformSelectOptions({
      data: purchaseSources?.purchaseSources,
    });
  };

  const getStatusOptions = () => {
    return transformSelectOptions({
      data: statuses?.purchaseParcelStatuses,
    });
  };

  return (
    <RequestHandler loading={loading}>
      <FormWrapper>
        <Formik
          initialValues={{
            id: Number(purchaseParcelData?.purchaseParcel?.id) || null,
            date: purchaseParcelData?.purchaseParcel?.date || '',
            source: purchaseParcelData?.purchaseParcel?.source?.id || '',
            status:
              purchaseParcelData?.purchaseParcel?.status?.id ||
              statuses?.purchaseParcelStatuses[0]?.id ||
              '',
            trackingCode: purchaseParcelData?.purchaseParcel?.trackingCode || '',
            forwardings: purchaseParcelData?.purchaseParcel?.forwardings || [],
            purchases: purchaseParcelData?.purchaseParcel?.purchases || [],
            trackingForwardingCodeQuantity:
              purchaseParcelData?.purchaseParcel?.forwardings?.length + 1 || 1,
          }}
          onSubmit={onSaveParcel}
          validationSchema={addParcelSchema}
        >
          {({ values, handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={isSaveParcelLoading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.source' variant='heading' />
                    <FieldRow>
                      <Box>
                        <Select
                          name='source'
                          label='app.source'
                          options={getSourceOptions()}
                        />
                        <TextField name={'trackingCode'} label={'app.trackingCode'} />
                      </Box>
                      <Box>
                        <Select
                          name='status'
                          label='app.status'
                          options={getStatusOptions()}
                        />
                        <DatePicker name='date' label='app.date' />
                      </Box>
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='app.forwardings' variant='heading' />
                    <FieldArray
                      name={'forwardings'}
                      render={(arrayHelpers) => (
                        <FieldRow>
                          {[...values.forwardings, {}].map((forwarding, index) => (
                            <QuerySelectWithSearch
                              key={index}
                              onChange={(option) => {
                                if (!option?.label) {
                                  arrayHelpers.remove(index);
                                }
                              }}
                              rowsInResponse
                              label={'app.trackingForwardingCode'}
                              fieldName={`forwardings.${index}`}
                              graphql={FIND_PURCHASE_FORWARDINGS_QUERY}
                              graphqlName='purchaseForwardings'
                              labelProperty={'trackingCode'}
                              valueProperty={['id', 'trackingCode']}
                              alreadyPickedItems={values.forwardings}
                            />
                          ))}
                        </FieldRow>
                      )}
                    />
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
              <ParcelStructure
                showForwarding={!!Object.values(values.forwardings).length}
              />
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Parcel;
