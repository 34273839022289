/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import { useField } from 'formik';
import { Checkbox, FlatList, Flex, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { Typography } from '../../../../components';
import { BoxIcon } from '../../../../components/Icons';
import inputStyles from '../../../../components/UI/Input/styles';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { ConditionNumber } from './';
import styles from './styles';

export const ItemConditionField = ({
  index,
  availableConditions,
  readOnly,
  condition,
  setShowLocation,
  showLocation,
  originalOrderItem,
}) => {
  const [allowedConditions, , helpers] = useField(
    `orderItems.${index}.availability.allowedConditions`,
  );

  const setDefaultConditionsAllowed = () => {
    const defaultConditions = availableConditions?.filter(({ isDefault }) => isDefault);
    if (defaultConditions?.length) helpers.setValue(defaultConditions);
  };

  useEffect(() => {
    if (!originalOrderItem?.availability?.allowedConditions?.length)
      setDefaultConditionsAllowed();
  }, [allowedConditions, originalOrderItem?.availability?.allowedConditions]);

  const [visible, setVisible] = useState(false);

  const DropdownButton = useRef();
  const [dropdownY, setDropdownY] = useState(null);
  const [dropdownX, setDropdownX] = useState(null);

  const toggleDropdown = () => (visible ? setVisible(false) : openDropdown());

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, w, h, px, py) => {
      setDropdownY(py + h);
      setDropdownX(IS_DESKTOP_SCREEN ? px : px - w);
    });
    setVisible(true);
  };

  const renderDropdown = () => (
    <Modal visible={visible && dropdownY && dropdownX} transparent animationType='none'>
      <TouchableOpacity style={styles.overlay} onPress={() => setVisible(false)}>
        <View style={[styles.dropdown, { top: dropdownY, left: dropdownX }]}>
          <FlatList
            data={availableConditions}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </TouchableOpacity>
    </Modal>
  );

  const renderItem = ({ item }) => (
    <TouchableOpacity key={item.id} activeOpacity={1}>
      <Checkbox
        isChecked={!!allowedConditions?.value?.find(({ id }) => +id === +item.id)}
        onChange={(isTrue) => handleChangeCheckbox(isTrue, item)}
      >
        <Flex>
          <ConditionNumber condition={item} style={styles.conditionDropdownNumber} />
          <Typography style={styles.conditionDropdownName}>{item.name}</Typography>
        </Flex>
      </Checkbox>
    </TouchableOpacity>
  );

  const renderConditionsInput = () => {
    const conditionsToRender = allowedConditions?.value?.filter((item) =>
      availableConditions.find(({ id }) => id === item.id),
    );

    return (
      <Flex
        style={[
          inputStyles.input,
          { overflow: 'hidden', paddingBottom: !conditionsToRender?.length ? 20 : 8 },
        ]}
        onClick={toggleDropdown}
        ref={DropdownButton}
      >
        {conditionsToRender?.map((item) => (
          <ConditionNumber key={item.id} condition={item} />
        ))}
        <MaterialIcons
          name={visible ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          size={17}
          color='black'
          onPress={toggleDropdown}
          style={styles.arrow}
        />
      </Flex>
    );
  };

  const handleChangeCheckbox = (isTrue, item) => {
    if (isTrue) return helpers.setValue([...(allowedConditions.value || []), item]);
    const removedValues = allowedConditions?.value?.filter(({ id }) => +id !== +item.id);
    helpers.setValue(removedValues);
  };

  if (!availableConditions?.length)
    return <Typography style={styles.notAvailable} intlId={'app.notAvailable'} />;

  if (readOnly)
    return (
      <View
        style={[
          styles.itemConditionField,
          showLocation && styles.itemConditionFieldActive,
        ]}
      >
        <Flex
          style={{ overflow: 'hidden' }}
          onClick={() => setShowLocation(!showLocation)}
          ref={DropdownButton}
        >
          <ConditionNumber
            condition={condition}
            style={styles.conditionNumber}
            fontSize={11}
          />
          <BoxIcon />
        </Flex>
      </View>
    );

  return (
    <>
      {renderConditionsInput()}
      {visible && renderDropdown()}
    </>
  );
};

ItemConditionField.propTypes = {
  index: PropTypes.number,
  availableConditions: PropTypes.array,
  status: PropTypes.string,
  statusWasChanged: PropTypes.string,
  readOnly: PropTypes.bool,
  condition: PropTypes.object,
  setShowLocation: PropTypes.func,
  showLocation: PropTypes.bool,
  originalOrderItem: PropTypes.object,
};
