import {
  BankIcon,
  BookkeepingCurrenciesIcon,
  BookkeepingMainIcon,
  BookkeepingTransactionsIcon,
} from '../../components/Icons';
import { BookkeepingExchangesIcon } from '../../components/Icons/BookkeepingExchangesIcon';

export default [
  {
    name: 'Home',
    icon: BookkeepingMainIcon,
    path: '/home',
    scopes: ['Report:Read', 'Currency:Read'],
  },
  {
    name: 'Currencies',
    icon: BookkeepingCurrenciesIcon,
    path: '/currencies',
    scopes: ['Currency:Read'],
  },
  {
    name: 'Transactions',
    icon: BookkeepingTransactionsIcon,
    path: '/transactions',
    scopes: ['Transaction:Read', 'Currency:Read'],
  },
  {
    name: 'Exchanges',
    icon: BookkeepingExchangesIcon,
    path: '/exchanges',
    scopes: ['Exchange:Read'],
  },
  {
    name: 'Accounts',
    icon: BankIcon,
    path: '/accounts',
    scopes: ['BankAccount:Read', 'Currency:Read'],
  },
];
