import { Formik } from 'formik';
import { Box, Flex } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextField,
  Typography,
} from '../../../components';
import CurrencyIdSelect from '../../../components/CurrencyIdSelect/CurrencyIdSelect';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import {
  useAddProductMutation,
  useGetCurrenciesQuery,
  useGetProduct,
  useHasScopesQuery,
  useParams,
} from '../../../hooks';
import { addProductSchema } from '../../../services/validation';
import { getVolumeWeight } from '../../../utils';
import { Availability } from './Availability/Availability';
import Barcodes from './Barcodes/Barcodes';
import UploadImage from './UploadImage/UploadImage';
import UploadImageMobile from './UploadImage/UploadImageMobile/UploadImageMobile';

const Product = () => {
  const { data, ...productQueryProps } = useGetProduct();

  const { data: hasScopesData } = useHasScopesQuery(['Purchase:Read']);
  const { data: currenciesData, ...currenciesQueryProps } = useGetCurrenciesQuery();
  const [addProduct, { loading }] = useAddProductMutation();

  const params = useParams();

  const hasScope = hasScopesData?.hasScopes[0]?.isAllowed;

  const handleFormSubmit = (values) => {
    addProduct({
      variables: {
        input: {
          id: params.id ? +params.id : undefined,
          name: values.name,
          sku: values.sku,
          weight: Number(values.weight),
          width: Number(values.width),
          height: Number(values.height),
          length: Number(values.length),
          price: Number(values.price),
          MSRprice: Number(values.MSRprice),
          priceCurrencyId: Number(values.priceCurrencyId),
          MSRpriceCurrencyId: Number(values.MSRpriceCurrencyId),
          barcodes: values.barcodes?.map((code) => Number(code)),
          photoId: +values.photoId ? +values.photoId : null,
          url: values.url,
        },
      },
    });
  };

  const initialValues = {
    name: data?.product?.name || '',
    sku: data?.product?.sku || '',
    weight: data?.product?.weight.toString() || '',
    width: data?.product?.width.toString() || '',
    height: data?.product?.height.toString() || '',
    length: data?.product?.length.toString() || '',
    price: data?.product?.price.toString() || '',
    priceCurrencyId: data?.product?.priceCurrencyId.toString() || '',
    MSRprice: data?.product?.MSRprice.toString() || '',
    MSRpriceCurrencyId: data?.product?.MSRpriceCurrencyId.toString() || '',
    addedBarcode: '',
    barcodes: data?.product?.barcodes.map((item) => item.barcode) || [],
    photoId: data?.product?.photo?.id || '',
    url: data?.product?.url || '',
  };

  return (
    <RequestHandler
      loading={productQueryProps.loading}
      error={productQueryProps.error}
      backgroundLoading={loading}
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={addProductSchema}
        >
          {({ values, handleSubmit }) => {
            return (
              <Layout
                onActionButtonPress={handleSubmit}
                actionButtonType='save'
                actionButtonIsLoading={loading}
              >
                <RequestHandler {...currenciesQueryProps}>
                  <FormRow>
                    <FormRow.Left>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.product' variant='heading' />
                        <FieldRow>
                          <TextField name='name' label='app.title' />
                          <TextField name='sku' label='app.sku' />
                        </FieldRow>
                      </BoxWrapper>
                      {!IS_DESKTOP_SCREEN ? (
                        <UploadImageMobile
                          photoFormats={data?.product?.photo}
                          loadingProduct={productQueryProps.loading}
                        />
                      ) : null}

                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.prices' variant='heading' />
                        <Flex
                          justify={'space-between'}
                          align={'center'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <Box
                            display={'flex'}
                            width={{ base: '100%', lg: '50%' }}
                            flexDirection={'row'}
                            justifyContent={{ base: 'space-between', lg: 'start' }}
                          >
                            <TextField
                              name='MSRprice'
                              label='app.MSRprice'
                              type='number'
                              /* eslint-disable react-native/no-inline-styles */
                              style={{ width: '58%' }}
                              mr={{ base: 0, lg: 6 }}
                            />
                            <CurrencyIdSelect
                              name='MSRpriceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                          <Box
                            display={'flex'}
                            width={{ base: '100%', lg: '50%' }}
                            flexDirection={'row'}
                            justifyContent={{ base: 'space-between', lg: 'end' }}
                          >
                            <TextField
                              name='price'
                              type='number'
                              label='app.price'
                              style={{ width: '58%' }}
                              mr={6}
                            />
                            <CurrencyIdSelect
                              name='priceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                        </Flex>
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Barcodes />
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.dimensions' variant='heading' />
                        <FieldRow withNegativeBottomMargin={false}>
                          <TextField
                            name='width'
                            label='app.widthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextField
                            name='length'
                            label='app.lengthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextField
                            name='height'
                            label='app.heightCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextField
                            name='weight'
                            label='app.weightKg'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </FieldRow>
                        <FieldRow position='flex-end'>
                          {IS_DESKTOP_SCREEN ? (
                            <>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                            </>
                          ) : (
                            <></>
                          )}
                          <TextField
                            name='volumeWeight'
                            value={getVolumeWeight(
                              values.width,
                              values.height,
                              values.length,
                              true,
                            )}
                            label='app.volumeWeight'
                            isReadOnly
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </FieldRow>
                      </BoxWrapper>
                    </FormRow.Left>
                    <FormRow.Right>
                      {IS_DESKTOP_SCREEN ? (
                        <UploadImage
                          photoFormats={data?.product?.photo}
                          loadingProduct={loading}
                        />
                      ) : null}
                    </FormRow.Right>
                  </FormRow>
                  {data?.product?.availabilities.length ? (
                    <Availability
                      hasScope={hasScope}
                      availabilities={data?.product?.availabilities}
                    />
                  ) : null}
                </RequestHandler>
              </Layout>
            );
          }}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Product;
