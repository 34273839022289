import { gql } from '@apollo/client';

import { FRAGMENT_PRODUCT, FRAGMENT_STOCK_PLACE } from './fragments';

export const GET_ORDERS = gql`
  query orders(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $search: String
    $filters: OrderFilterInput!
  ) {
    orders(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      search: $search
      filters: $filters
    ) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      paymentStatus {
        id
        name
      }
      orderItems {
        id
        pricePerPcs
        quantity
      }
      discount
    }
  }
`;

export const GET_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        middleName
        emails {
          id
          email
        }
        phones {
          id
          phone
        }
      }
      source {
        id
        name
      }
      status {
        id
        name
        reservationType
      }
      orderItems {
        id
        pricePerPcs
        quantity
        product {
          id
          name
          url
          avaliableConditions {
            id
            name
            isDefault
          }
        }
        availability {
          allowedConditions {
            id
            name
            isDefault
          }
          condition {
            id
            name
          }
        }

        place {
          id
          name
          location {
            id
            name
            parentId
            typeId
          }
        }
      }
      discount
      description
      deliveryInformation {
        id
        address
        comment
        ttn
        city {
          id
          name
          region {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
      novaPoshtaShipping {
        addressRef
        city {
          ref
          description
        }
        warehouse {
          ref
          description
        }
        street
        buildingNumber
        flatNumber
        payerType
        serviceType
      }
      shippingType {
        id
        name
      }
      paymentType {
        id
        name
      }
      paymentStatus {
        id
        name
      }
    }
    statuses {
      id
      name
      reservationType
    }
    users {
      id
      firstName
      lastName
    }
    sources {
      id
      name
    }
    cities {
      id
      name
      region {
        id
        name
        country {
          id
          name
        }
      }
    }
    shippingTypes {
      id
      name
    }
    paymentTypes {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
    customers {
      id
      firstName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_ORDER_FIELDS = gql`
  {
    statuses {
      id
      name
      reservationType
    }
    users {
      id
      firstName
      lastName
    }
    sources {
      id
      name
    }
    cities {
      id
      name
      region {
        id
        name
        country {
          id
          name
        }
      }
    }
    shippingTypes {
      id
      name
    }
    paymentTypes {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
    customers {
      id
      firstName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_ORDER_ITEMS = gql`
  ${FRAGMENT_PRODUCT}
  query orderItems($ids: [ID], $source: ID!) {
    orderItems(ids: $ids) {
      id
      quantity
      pricePerPcs
      product {
        ...FragmentProduct
        source(id: $source) {
          price
        }
      }
    }
  }
`;

export const CUSTOMERS_FILTER = gql`
  {
    customers {
      id
      firstName
      lastName
      createdAt
    }
    phones {
      id
      phone
    }
    emails {
      id
      email
    }
  }
`;

export const HAS_SCOPES_QUERY = gql`
  query hasScopes($scopes: [String]!) {
    hasScopes(scopes: $scopes) {
      isAllowed
      scope
    }
  }
`;

export const ORDERS_FILTER = gql`
  {
    statuses {
      id
      name
    }
    users {
      id
      firstName
      lastName
    }
    customers {
      id
      firstName
      lastName
      phones {
        id
        phone
      }
    }
    sources {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {
    currentUser {
      avatar
      firstName
      lastName
      roles {
        name
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query customers(
    $search: String
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $filters: CustomerFilterInput
  ) {
    customers(
      search: $search
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filters: $filters
    ) {
      id
      firstName
      lastName
      createdAt
      phones {
        id
        phone
      }
      emails {
        id
        email
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
      children {
        id
        name
        birthday
        sex
        relationType {
          id
          name
        }
      }
      orders {
        id
        createdAt
        source {
          id
          name
        }
        status {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
        discount
      }
    }
    relationTypes {
      id
      name
    }
  }
`;
export const GET_CUSTOMER_OPTION = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_CUSTOMER_FIELDS = gql`
  {
    relationTypes {
      id
      name
    }
  }
`;

export const GET_STOCK_LOCATIONS = gql`
  query {
    stockLocations {
      id
      name
      parentId
      typeId
    }
  }
`;

export const GET_PURCHASE = gql`
  query purchase($id: ID!) {
    purchase(id: $id) {
      id
      price
      deliveryPrice
      purchaseTime
      quantity
      product {
        id
        name
        price
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
      }
      forwarding {
        id
        trackingCode
      }
    }
  }
`;

export const GET_STOCK_PLACES = gql`
  ${FRAGMENT_STOCK_PLACE}
  query stockPlaces(
    $search: String
    $archival: Boolean
    $locationIds: [ID]
    $offset: Int
    $limit: Int
    $sort: Sort
  ) {
    stockPlaces(
      search: $search
      archival: $archival
      locationIds: $locationIds
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      ...FragmentStockPlace
    }
  }
`;

export const GET_STOCK_PLACE = gql`
  ${FRAGMENT_STOCK_PLACE}
  query stockPlace($id: ID!) {
    stockPlace(id: $id) {
      ...FragmentStockPlace
      availabilities {
        quantity
        reservedQuantity
        purchase {
          id
          price
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  ${FRAGMENT_PRODUCT}
  query products($ids: [ID], $search: String, $limit: Int, $offset: Int) {
    products(ids: $ids, search: $search, limit: $limit, offset: $offset) {
      ...FragmentProduct
    }
  }
`;

export const FIND_PRODUCTS = gql`
  ${FRAGMENT_PRODUCT}
  query products($search: String, $source: ID!) {
    products(search: $search) {
      ...FragmentProduct
      source(id: $source) {
        price
        availability
      }
    }
  }
`;

export const GET_USERS = gql`
  query filteredUsers($offset: Int, $limit: Int, $search: String) {
    filteredUsers(offset: $offset, limit: $limit, search: $search) {
      id
      firstName
      lastName
      createdAt
      phone
      email
      avatar
      roles {
        id
        name
      }
    }
  }
`;

export const GET_AVAILABILITY = gql`
  query availability($filters: AvailabilityFilterInput) {
    availability(filters: $filters) {
      quantity
      condition {
        id
        name
        describe
      }
      place {
        id
        archival
        name
        location {
          id
          name
        }
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
        product {
          id
          name
          url
          image
          sku
          price
          quantity
        }
      }
    }
  }
`;

export const LOGIN_QUERY = gql`
  query loginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      token
    }
  }
`;

export const PURCHASES_QUERY = gql`
  query purchases(
    $offset: Int
    $parcelId: Int
    $forwardingId: Int
    $limit: Int
    $search: String
    $forwardingIdViaParcel: Int
  ) {
    purchases(
      offset: $offset
      parcelId: $parcelId
      forwardingId: $forwardingId
      limit: $limit
      search: $search
      forwardingIdViaParcel: $forwardingIdViaParcel
    ) {
      id
      product {
        id
        name
        price
        sku
        photo {
          path
        }
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
        status {
          id
          name
        }
      }
      forwarding {
        id
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
      }
      price
      deliveryPrice
      purchaseTime
      quantity
    }
  }
`;

export const FIND_PURCHASE_PARSELS_QUERY = gql`
  query findPurchaseParcels($offset: Int!, $limit: Int!, $search: String) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
      }
    }
  }
`;

export const FIND_PURCHASE_FORWARDINGS_QUERY = gql`
  query findPurchaseForwardings($offset: Int!, $limit: Int!, $search: String) {
    purchaseForwardings(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
        date
      }
    }
  }
`;

export const GET_USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      status
      email
      phone
      skype
      roles {
        id
        name
      }
    }
  }
`;

export const GET_ROLES_QUERY = gql`
  query {
    userRoles {
      id
      name
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  ${FRAGMENT_PRODUCT}
  query product($id: ID, $barcode: BigInt) {
    product(id: $id, barcode: $barcode) {
      ...FragmentProduct
    }
  }
`;

export const GET_SOURCES_QUERY = gql`
  query {
    sources {
      id
      name
    }
  }
`;

export const GET_PURCHASE_PARCEL_STATUSES_QUERY = gql`
  query {
    purchaseParcelStatuses {
      id
      name
    }
  }
`;

export const GET_PURCHASE_FORWARDING_STATUSES_QUERY = gql`
  query {
    purchaseForwardingStatuses {
      id
      name
    }
  }
`;

export const GET_PURCHASE_SOURCES_QUERY = gql`
  query {
    purchaseSources {
      id
      name
    }
  }
`;

export const GET_PURCHASE_FORWARDINGS_QUERY = gql`
  query getPurchaseForwardings($offset: Int!, $limit: Int!, $search: String) {
    purchaseForwardings(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        date
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            id
            length
            width
            height
            weight
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_FORWARDING_PARCELS_QUERY = gql`
  ${FRAGMENT_PRODUCT}
  query purchaseParcels(
    $offset: Int!
    $limit: Int!
    $search: String
    $ids: [ID]
    $source: ID!
  ) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search, ids: $ids) {
      count
      rows {
        id
        trackingCode
        date
        status {
          id
          name
        }
        source {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            ...FragmentProduct
            source(id: $source) {
              price
              availability
            }
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_PARCELS_QUERY = gql`
  query purchaseParcels($offset: Int!, $limit: Int!, $search: String) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
        date
        status {
          id
          name
        }
        source {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            id
            length
            width
            height
            weight
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_PARCEL_QUERY = gql`
  query purchaseParcel($id: ID!) {
    purchaseParcel(id: $id) {
      id
      trackingCode
      date
      forwardings {
        id
        trackingCode
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      purchases {
        id
        price
        quantity
        product {
          id
          name
        }
        forwarding {
          id
          trackingCode
        }
      }
    }
  }
`;

export const GET_PURCHASE_FORWARDING_QUERY = gql`
  query purchaseForwarding($id: ID!) {
    purchaseForwarding(id: $id) {
      id
      trackingCode
      date
      deliveryPrice
      parcels {
        id
        trackingCode
      }
      status {
        id
        name
      }
      purchases {
        id
        price
        quantity
        deliveryPrice
        parcel {
          id
          trackingCode
        }
        product {
          id
          weight
          name
        }
      }
    }
  }
`;

export const GET_CURRENCIES_QUERY = gql`
  query currencies {
    currencies {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
        currencyId
      }
      lastRate {
        id
        ratio
        date
        dateTo
        currencyId
      }
    }
  }
`;

export const GET_CURRENCY_QUERY = gql`
  query currency($id: ID!) {
    currency(id: $id) {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
        currencyId
      }
      lastRate {
        id
        ratio
        date
        dateTo
        currencyId
      }
    }
  }
`;

export const GET_CURRENCY_RATIO_QUERY = gql`
  query currency($id: ID!) {
    currency(id: $id) {
      id
      lastRate {
        ratio
      }
    }
  }
`;

export const GET_EXCHANGE_QUERY = gql`
  query exchange($id: ID!) {
    exchange(id: $id) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const GET_EXCHANGES_QUERY = gql`
  query exchanges(
    $offset: Int
    $limit: Int
    $order: String
    $search: String
    $filters: ExchangeFilterInput
  ) {
    exchanges(
      offset: $offset
      limit: $limit
      order: $order
      search: $search
      filters: $filters
    ) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const GET_REPORTS_QUERY = gql`
  query reports($filters: ReportFilterInput) {
    reports(filters: $filters) {
      income
      exchange
      spend
      bankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const GET_TRANSACTIONS_QUERY = gql`
  query transactions(
    $offset: Int
    $limit: Int
    $order: String
    $search: String
    $filters: TransactionFilterInput
  ) {
    transactions(
      offset: $offset
      limit: $limit
      order: $order
      search: $search
      filters: $filters
    ) {
      id
      typeId
      date
      amount
      comment
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currency {
          id
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }

      order {
        id
        createdAt
      }
      user {
        id
        firstName
        lastName
      }
      forwarding {
        id
        trackingCode
      }
      purchase {
        id
        purchaseTime
      }
    }
  }
`;

export const GET_TRANSACTION_QUERY = gql`
  query transaction($id: ID!) {
    transaction(id: $id) {
      id
      typeId
      date
      amount
      comment
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currencyId
        currency {
          id
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }
      order {
        id
        createdAt
        user {
          id
          firstName
          lastName
        }
        customer {
          id
          firstName
          lastName
        }
        source {
          id
          name
        }
        status {
          id
          name
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
        discount
      }
      user {
        id
        firstName
        lastName
      }
      forwarding {
        id
        trackingCode
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
        product {
          id
          name
          price
          weight
          width
          height
          length
        }
        parcel {
          id
          trackingCode
          status {
            id
            name
          }
        }
        forwarding {
          id
          trackingCode
          deliveryPrice
          status {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_TYPES_QUERY = gql`
  query {
    transactionTypes {
      id
      name
    }
  }
`;

export const GET_CONDITIONS_QUERY = gql`
  query {
    conditions {
      id
      name
    }
  }
`;

export const GET_PACKAGING_TEMPLATES = gql`
  query packagingTemplates($search: String) {
    packagingTemplates(search: $search) {
      id
      name
      width
      length
      height
      weight
    }
  }
`;

export const GET_PACKAGING_TEMPLATE = gql`
  query packagingTemplate($id: Int!) {
    packagingTemplate(id: $id) {
      id
      name
      width
      length

      height
      weight
    }
  }
`;

export const GET_CITY = gql`
  query city($id: ID!) {
    city(id: $id) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

export const FIND_CITIES = gql`
  query findCities($search: String) {
    findCities(search: $search) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

export const GET_WAREHOUSES_NP = gql`
  query getWarehousesNovaPoshta($warehouseRef: String, $cityRef: String) {
    getWarehousesNovaPoshta(warehouseRef: $warehouseRef, cityRef: $cityRef) {
      ref
      description
    }
  }
`;

export const GET_CITIES_NP = gql`
  query getCitiesNovaPoshta($cityName: String, $cityRef: String) {
    getCitiesNovaPoshta(cityName: $cityName, cityRef: $cityRef) {
      ref
      description
    }
  }
`;

export const GET_STREETS_NP = gql`
  query getStreetNovaPoshta($cityRef: String!, $streetName: String) {
    getStreetNovaPoshta(cityRef: $cityRef, streetName: $streetName) {
      ref
      description
      type
    }
  }
`;

export const GET_PAYERS_TYPES_NP = gql`
  query {
    getTypesOfPayersNovaPoshta {
      ref
      description
    }
  }
`;

export const GET_PHOTO_BY_ID = gql`
  query usePhotoById($photoId: ID!) {
    photo(id: $photoId) {
      id
      alt
      path
    }
  }
`;
export const GET_BANKS_ACCOUNTS = gql`
  query bankAccounts($filters: BankAccountFilterInput, $sort: Sort) {
    bankAccounts(filters: $filters, sort: $sort) {
      id
      title
      description
      currencyId
      currency {
        id
        name
        code
        symbol
        currencyRates {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
    }
  }
`;

export const GET_BANK_ACCOUNT = gql`
  query bankAccount($id: ID!) {
    bankAccount(id: $id) {
      id
      title
      description
      currencyId
      hasTransactions
      currency {
        id
        name
        code
        symbol
        currencyRates {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
    }
  }
`;
