/* eslint-disable react-native/no-inline-styles */
import { Formik } from 'formik';
import { View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  BoxWrapper,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextField,
  Typography,
} from '../../../components';
import { Preloader } from '../../../components/UI';
import { DEVICE_WIDTH, displayWidth } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import {
  useAddPackagingTemplate,
  useDeletePackagingTemplate,
  useGetPackagingTemplate,
  useParams,
} from '../../../hooks';
import { addPackagingTemplateSchema } from '../../../services/validation';
import { getVolumeWeight } from '../../../utils';
import RemovePackagModal from './RemovePackagModal';

const PackagingTemplate = () => {
  const intl = useIntl();
  const width = DEVICE_WIDTH >= displayWidth;
  const params = useParams();

  const { data, loading } = useGetPackagingTemplate();
  const [mutation] = useAddPackagingTemplate();
  const [remove] = useDeletePackagingTemplate();

  const [openModal, setOpenModal] = useState(false);

  const layout = data?.packagingTemplate;

  const initialValues = {
    name: layout?.name || '',
    width: layout?.width ? String(layout?.width / 100) : '',
    height: layout?.height ? String(layout?.height / 100) : '',
    length: layout?.length ? String(layout?.length / 100) : '',
    weight: layout?.weight ? String(layout?.weight / 1000) : '',
    volume: layout?.volume ? String(layout?.volume) : '',
  };

  const onSubmit = async ({ name, width, height, length, weight }) =>
    mutation({
      variables: {
        input: {
          id: params.id ? +params.id : undefined,
          name: name,
          width: +width * 100,
          height: +height * 100,
          length: +length * 100,
          weight: +weight * 1000,
        },
      },
    });

  const onRemove = () => {
    setOpenModal(true);
  };

  const removePackage = () => {
    remove({ variables: { id: +params.id } });
  };

  if (loading) return <Preloader />;

  return (
    <>
      <RequestHandler loading={loading}>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={addPackagingTemplateSchema}
          >
            {({ handleSubmit, values }) => (
              <Layout
                onActionButtonPress={handleSubmit}
                actionButtonType='save'
                onRemoveButtonPress={onRemove}
              >
                <FormRow>
                  <FormRow.Left>
                    <BoxWrapper>
                      <RequestHandler loading={false}>
                        <Typography intlId='app.title' variant='heading' />
                        <TextField name='name' />
                      </RequestHandler>
                    </BoxWrapper>
                  </FormRow.Left>
                  <FormRow.Right>
                    <BoxWrapper>
                      <Typography intlId='app.params' variant='heading' />
                      <View style={[{ marginTop: 10 }, stylesGlobal.formWrapper]}>
                        <View style={width && { width: '30%' }}>
                          <TextField
                            name='width'
                            label='app.widthCm'
                            type='number'
                            numberFixedCount={2}
                          />
                        </View>
                        <View style={width && { width: '30%' }}>
                          <TextField
                            name={'length'}
                            label={'app.lengthCm'}
                            type='number'
                            numberFixedCount={2}
                          />
                        </View>
                        <View style={width && { width: '30%' }}>
                          <TextField
                            name={'height'}
                            label={'app.heightCm'}
                            type='number'
                            numberFixedCount={2}
                          />
                        </View>
                      </View>
                      <View style={[{ marginTop: 10 }, stylesGlobal.formWrapper]}>
                        <View style={width && { width: '30%' }}>
                          <TextField
                            name={'weight'}
                            label={'app.weightKg'}
                            type='number'
                            numberFixedCount={3}
                          />
                        </View>
                        <View style={width && { width: '30%' }}>
                          <TextField
                            name={'volume'}
                            label={'app.volumeWeight'}
                            value={`${getVolumeWeight(
                              values.length,
                              values.height,
                              values.width,
                              true,
                            )} ${intl.formatMessage({ id: 'app.kg' })}`}
                            inputProps={{
                              isReadOnly: true,
                            }}
                            numberFixedCount={3}
                          />
                        </View>
                      </View>
                    </BoxWrapper>
                  </FormRow.Right>
                </FormRow>
              </Layout>
            )}
          </Formik>
        </FormWrapper>
      </RequestHandler>
      <RemovePackagModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={removePackage}
      />
    </>
  );
};

PackagingTemplate.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    length: PropTypes.number,
    weight: PropTypes.number,
    volume: PropTypes.number,
  }),
};

export default PackagingTemplate;
