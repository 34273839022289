import { Formik } from 'formik';
import { Box } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  Checkbox,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import {
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetBankAccount,
  useGetCurrenciesQuery,
  useParams,
} from '../../../hooks';
import { accountSchema } from '../../../services/validation';
import { transformSelectOptions } from '../../../utils';

const Account = () => {
  const params = useParams();

  const { data: bankAccount, ...bankAccountProps } = useGetBankAccount();
  const { data: currencies, ...currenciesProps } = useGetCurrenciesQuery();

  const [createBankAccount, { loading: isCreateBankLoading }] =
    useCreateBankAccountMutation();
  const [remove] = useDeleteBankAccountMutation();

  const onFormSubmit = ({ currencyId, ...values }) => {
    createBankAccount({
      variables: {
        input: {
          ...values,
          id: params.id ? +params.id : undefined,
          currencyId: Number(currencyId),
        },
      },
    });
  };

  const onRemove = () => remove({ variables: { id: +params.id } });

  const loading = currenciesProps.loading || bankAccountProps.loading;
  const error = currenciesProps.error || bankAccountProps.error;
  const initialValues = {
    title: bankAccount?.bankAccount?.title || '',
    currencyId: bankAccount?.bankAccount?.currencyId?.toString() || '',
    description: bankAccount?.bankAccount?.description || '',
    isArchived: Boolean(bankAccount?.bankAccount?.isArchived),
  };

  return (
    <RequestHandler
      loading={loading}
      error={error}
      backgroundLoading={isCreateBankLoading}
    >
      <FormWrapper>
        <Formik
          validationSchema={accountSchema}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={isCreateBankLoading}
              onRemoveButtonPress={!bankAccount?.bankAccount?.hasTransactions && onRemove}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId={'app.data'} variant='heading' />
                    <FieldRow>
                      <TextField
                        label={'app.title'}
                        placeholderIntlId={'app.enterSpecification'}
                        inputProps={{ pb: '9px' }}
                        name='title'
                        type='text'
                      />
                      <Box w={'200px'}>
                        <Select
                          name='currencyId'
                          label='app.type'
                          options={transformSelectOptions({
                            data: currencies?.currencies,
                            label: 'code',
                          })}
                        />
                      </Box>
                    </FieldRow>
                    <Checkbox
                      mt={!IS_MOBILE_SCREEN ? '16px' : 0}
                      justify={!IS_MOBILE_SCREEN ? 'flex-end' : 'center'}
                      label={'app.archived'}
                      onChange={() => setFieldValue('isArchived', !values.isArchived)}
                      checked={values.isArchived}
                    />
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId={'app.specification'} variant='heading' />
                    <TextField
                      placeholderIntlId={'app.enterSpecification'}
                      inputProps={{ pb: '9px' }}
                      name='description'
                      type='text'
                    />
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Account;
