import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  OrderMobileItem,
  RequestHandler,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useHistory, useOrderData, useOrdersQuery } from '../../../hooks';
import { OrdersFilters } from './OrdersFilters';

const COLUMNS = [
  { intlId: 'app.id', sortId: 'id' },
  { intlId: 'app.dateTime', sortId: 'datetime' },
  { intlId: 'app.manager', sortId: 'manager' },
  { intlId: 'app.client', sortId: 'customer' },
  { intlId: 'app.source', sortId: 'source' },
  { intlId: 'app.payment', sortId: 'payment' },
  { intlId: 'app.status', sortId: 'status' },
  { intlId: 'app.sum', sortId: 'amount' },
];

const Orders = () => {
  const navigation = useHistory();
  const { data, fetchMore, ...queryProps } = useOrdersQuery();
  const { getOrderData, getOrderStatusColor } = useOrderData();

  const handleAddButtonClick = () => {
    navigation.push('/sales/order/');
  };

  const handleEditButtonClick = (id) => {
    navigation.push('/sales/order/' + id);
  };

  const fetchMoreData = (resolve, reject) =>
    fetchMore({
      variables: {
        offset: data?.orders?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          orders: [...prev?.orders, ...fetchMoreResult?.orders],
        };
      },
    });

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout
        isScrollable={false}
        onActionButtonPress={handleAddButtonClick}
        filters={<OrdersFilters />}
        onEndReached={fetchMoreData}
      >
        <RequestHandler {...queryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.orders?.map((order) => (
                <DesktopTable.Row
                  key={order?.id}
                  onEditButtonClick={() => handleEditButtonClick(order?.id)}
                >
                  <DesktopTable.Cell>{getOrderData('id', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('date', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('manager', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('customer', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('source', order)}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getOrderData('paymentStatus', order)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell style={getOrderStatusColor(order)}>
                    {getOrderData('status', order)}
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{getOrderData('amount', order)}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout
      isScrollable={false}
      onActionButtonPress={handleAddButtonClick}
      filters={<OrdersFilters />}
    >
      <RequestHandler {...queryProps}>
        <MobileTable
          data={data?.orders}
          sortableColumns={COLUMNS}
          handleEndReached={fetchMoreData}
          renderItem={(order) => (
            <OrderMobileItem
              onItemPress={handleEditButtonClick}
              id={getOrderData('id', order)}
              date={getOrderData('date', order)}
              managerName={getOrderData('manager', order)}
              customerName={getOrderData('customer', order)}
              sourse={getOrderData('source', order)}
              paymentStatus={getOrderData('paymentStatus', order)}
              status={getOrderData('statusId', order)}
              amount={getOrderData('amount', order)}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default Orders;
