import { FieldArray, useField } from 'formik';
import { Box } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import {
  FieldRow,
  FormRow,
  QuerySelectWithSearch,
  RequestHandler,
  TextField,
  TextLink,
} from '../../../../components';
import stylesGlobal from '../../../../globalStyles';
import { GET_CUSTOMERS } from '../../../../graphql';
import { useGetCustomerLazyQuery } from '../../../../hooks';
import { getContacts } from '../../../../utils';
import { CustomerContact } from '../../Customer/CustomerContact';
import stylesLocal from './styles';

const OrderCustomer = () => {
  const { formatMessage } = useIntl();

  const [field, , helpers] = useField('customer');

  const [getCustomerLazyQuery, { loading }] = useGetCustomerLazyQuery();

  const onCustomersSelectChange = async (option) => {
    if (option?.value) {
      const customerResponse = await getCustomerLazyQuery({
        variables: { id: +option.value },
      });

      const { id, phones, emails, ...otherProps } = customerResponse?.data?.customer;

      helpers.setValue({
        id: +id,
        contacts: getContacts(phones, emails),
        ...otherProps,
      });
    } else {
      await helpers.setValue({
        id: null,
        firstName: '',
        lastName: '',
        middleName: '',
        contacts: [''],
      });
    }
  };
  const initialCustomerOption = field?.value?.id
    ? {
        value: field?.value?.id,
        label: `${field?.value?.firstName || ''} ${field?.value?.lastName || ''} ${
          field?.value?.contacts[0] || ''
        }`,
      }
    : null;

  return (
    <RequestHandler backgroundLoading={loading}>
      <View style={stylesLocal.header}>
        <Title style={stylesGlobal.title}>{formatMessage({ id: 'app.customer' })}</Title>
        <QuerySelectWithSearch
          width={'100%'}
          initialOption={initialCustomerOption}
          placeholderIntlId='new.customer'
          fieldName='customer.id'
          labelProperty={['firstName', 'lastName', 'phones.[0].phone']}
          pl={0}
          pr={0}
          onChange={onCustomersSelectChange}
          graphql={GET_CUSTOMERS}
          graphqlName='customers'
        />
      </View>
      <FormRow style={stylesLocal.fields}>
        <FormRow.Left>
          <FieldArray
            name={'customer.contacts'}
            render={(arrayHelpers) => (
              <FieldRow>
                {[
                  ...(field?.value?.contacts || []),
                  ...(field?.value?.contacts?.[0] === '' ? [] : [{}]),
                ].map((_, index) => {
                  return (
                    <CustomerContact
                      width='100%'
                      removeContact={arrayHelpers.remove}
                      key={index}
                      index={index}
                      name={`customer.contacts.${index}`}
                    />
                  );
                })}
              </FieldRow>
            )}
          />
        </FormRow.Left>
        <FormRow.Right>
          <TextField name='customer.lastName' label='customer.lastName' />
          <TextField name='customer.firstName' label='customer.name' />
          <TextField name='customer.middleName' label='customer.middleName' />
        </FormRow.Right>
      </FormRow>
      {field?.value?.id && (
        <Box ml={2} pt={field?.value?.contacts?.[2] ? 6 : 0} pb={3}>
          <TextLink
            link={'/sales/customer/' + field?.value?.id}
            underline
            intlId='customer.profile'
          />
        </Box>
      )}
    </RequestHandler>
  );
};

OrderCustomer.propTypes = {};

export default OrderCustomer;
