import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useDebounce, useRoute } from '../hooks';

const SearchContext = createContext();
const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const { debouncedValue: debouncedSearchValue, isDebouncing } = useDebounce(searchValue);

  const { path } = useRoute();
  const initPathRef = React.useRef();

  React.useEffect(() => {
    if (!initPathRef.current) {
      initPathRef.current = path;
    } else if (initPathRef.current !== path) {
      setSearchValue('');
    }
  }, [path]);

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        debouncedSearchValue,
        isDebouncing,
        changeSearchValue: setSearchValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SearchProvider, useSearchContext };
