import { MaterialIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import { Icon } from 'native-base';
import React from 'react';

import {
  BoxWrapper,
  DatePicker,
  FieldRow,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TextField,
  Typography,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useAddCurrencyMutation, useGetCurrencyQuery, useParams } from '../../../hooks';
import { currencySchema } from '../../../services/validation';
import { CurrencyHistory } from './CurrencyHistory/CurrencyHistory';

const Currency = () => {
  const params = useParams();
  const { data, ...queryProps } = useGetCurrencyQuery();
  const [currencyMutation, { loading }] = useAddCurrencyMutation();

  const handleFormSubmit = (values) => {
    currencyMutation({
      variables: {
        input: {
          name: values.name,
          code: values.code,
          symbol: values.symbol,
          newRate: {
            ratio: values.ratio,
            date: Date.parse(values.date),
          },
          ...(params?.id ? { id: Number(data?.currency?.id) } : {}),
          ...(values?.lastRateRemoved
            ? { lastRateRemoved: Number(values.lastRateRemoved) }
            : {}),
        },
      },
    });
  };

  const initialValues = {
    name: data?.currency?.name || '',
    code: data?.currency?.code || '',
    symbol: data?.currency?.symbol || '',
    ratio: '',
    reverseCourse: '',
    date: new Date(),
    lastRateRemoved: 0,
  };

  return (
    <RequestHandler {...queryProps}>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={currencySchema}
        >
          {({ handleSubmit }) => (
            <Layout
              onActionButtonPress={handleSubmit}
              actionButtonType='save'
              actionButtonIsLoading={loading}
            >
              <FormRow>
                <FormRow.Left>
                  <BoxWrapper>
                    <Typography intlId='app.currency' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField name='name' label='app.title' />
                    </FieldRow>
                    <FieldRow>
                      <TextField name='code' label='app.code' />
                      <TextField name='symbol' label='app.symbol' />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Left>
                <FormRow.Right>
                  <BoxWrapper>
                    <Typography intlId='app.rate' variant='heading' />
                    <FieldRow withNegativeBottomMargin={false}>
                      <TextField
                        isRatioToReverseCourse={true}
                        type='number'
                        name='ratio'
                        label='app.ratio'
                      />
                      <Icon
                        as={<MaterialIcons name='link' />}
                        color='#965A85'
                        size='sm'
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          flex: 0,
                          position: 'relative',
                          bottom: IS_MOBILE_SCREEN ? 5 : -20,
                        }}
                      />
                      <TextField
                        isReverseCourseToRatio={true}
                        type='number'
                        name='reverseCourse'
                        label='app.reverseCourse'
                      />
                    </FieldRow>
                    <FieldRow>
                      <DatePicker name='date' label='date.fromStart' />
                    </FieldRow>
                  </BoxWrapper>
                </FormRow.Right>
              </FormRow>
              <CurrencyHistory data={data?.currency?.currencyRates} />
            </Layout>
          )}
        </Formik>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Currency;
