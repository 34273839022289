import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useFilter, useRoute } from '../hooks';

const FiltersContext = createContext();
const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
};

const FiltersProvider = ({ children }) => {
  const { filters } = useFilter();
  const { path } = useRoute();
  const initPathRef = React.useRef();

  const [isOpen, setIsOpen] = React.useState(
    filters &&
      Object.keys(filters)?.length > 0 &&
      JSON.stringify(Object.keys(filters)) !== `["direction","field"]`,
  );

  React.useEffect(() => {
    if (!initPathRef.current) {
      initPathRef.current = path;
    } else if (initPathRef.current !== path) {
      initPathRef.current = path;
      setIsOpen(false);
    }
  }, [path]);

  return (
    <FiltersContext.Provider
      value={{
        isFilterOpen: isOpen,
        toggleFilter: () => setIsOpen((prevState) => !prevState),
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FiltersProvider, useFiltersContext };
