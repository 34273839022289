/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation as useLocationWeb } from 'react-router-dom';
import { useLocation as useLocationNative } from 'react-router-native';

import { IS_APP } from '../constants';

const useRoute = () => {
  const location = IS_APP ? useLocationNative() : useLocationWeb();

  return {
    ...location,
    root: location.pathname.split('/')[1]?.toLowerCase(),
    path: location.pathname.split('/')[2]?.toLowerCase(),
  };
};

export default useRoute;
