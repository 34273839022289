import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Keyboard, TextInput, View } from 'react-native';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { styles } from './styles';

export const SearchField = ({
  value,
  onChange,
  isOpen,
  onOpen = () => {},
  onClose = () => {},
}) => {
  const ref = useRef();

  useEffect(() => {
    if (isOpen) {
      ref.current.focus();
    }
  }, [isOpen]);

  const handleClose = () => {
    onChange('');
    Keyboard.dismiss();
    onClose();
  };

  return (
    <View style={isOpen ? styles.wrapperOpen : styles.wrapper}>
      <MaterialIcons
        name='search'
        size={25}
        color='#fff'
        style={[
          IS_DESKTOP_SCREEN ? styles.iconWeb : styles.iconMobile,
          isOpen ? styles.iconOpen : null,
        ]}
        onPress={!IS_DESKTOP_SCREEN ? onOpen : null}
      />
      <TextInput
        ref={ref}
        value={value}
        onChangeText={onChange}
        style={[
          styles.input,
          isOpen || IS_DESKTOP_SCREEN ? styles.inputOpen : styles.inputMobile,
        ]}
      />
      {isOpen ? (
        <MaterialIcons
          name='close'
          size={25}
          color='#fff'
          style={styles.closeIcon}
          onPress={handleClose}
        />
      ) : null}
    </View>
  );
};

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};
