/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React from 'react';
import { Title } from 'react-native-paper';

import {
  DatePicker,
  FormRow,
  Select,
  TextField,
  Typography,
} from '../../../../components';
import globalStyles from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { NovaPoshtaFields } from './NovaPoshtaFields';
import styles from './styles';

const DeliveryProps = ({ shippingTypes, selectedShippingType }) => {
  const intl = useIntl();

  return (
    <>
      <Title style={globalStyles.title}>
        {intl.formatMessage({ id: 'app.delivery' })}
      </Title>
      <FormRow style={globalStyles.formWrapper}>
        <FormRow.Left>
          <Select
            name='shippingType'
            label={'app.deliveryOption'}
            options={transformSelectOptions({
              data: [shippingTypes[0]],
              label: 'name',
            })}
          />
        </FormRow.Left>
        <FormRow.Right>
          <DatePicker
            name='deliveryInformation.departureDate'
            label='app.departureDate'
            style={styles.departureDate}
            // eslint-disable-next-line react-native/no-inline-styles
            inputStyles={{
              fontSize: 14,
              height: 20,
              paddingBottom: 8,
              paddingLeft: 5,
              paddingRight: 5,
              paddingTop: 2,
            }}
          />
        </FormRow.Right>
      </FormRow>
      {selectedShippingType &&
        (selectedShippingType === '1' ? (
          <NovaPoshtaFields />
        ) : (
          <Typography intlId='app.noData' />
        ))}
      <TextField name='deliveryInformation.comment' label='order.deliveryComment' />
    </>
  );
};

DeliveryProps.propTypes = {
  shippingTypes: PropTypes.array.isRequired,
  selectedShippingType: PropTypes?.string,
};

export default DeliveryProps;
