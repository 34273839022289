import { Formik } from 'formik';
import { Box, Flex } from 'native-base';
import React from 'react';

import { Button, Checkbox, RequestHandler, Select } from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useFilter, useGetCurrenciesQuery } from '../../../hooks';
import { transformSelectOptions } from '../../../utils';

export const AccountsFilter = () => {
  const { filters, setFilters, clearFilters } = useFilter();
  const { data: currencies, ...bankAccountsQueryProps } = useGetCurrenciesQuery();
  const { toggleFilter } = useFiltersContext();

  const handleFormSubmit = ({ currencyId, isArchived }) => {
    setFilters({
      currencyId,
      isArchived,
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const handleResetForm = (setFieldValue) => {
    setFieldValue('currencyId', '');
    setFieldValue('isArchived', '');
    clearFilters();
  };

  const initialValues = {
    currencyId: filters?.currencyId?.toString(),
    isArchived: Boolean(filters?.isArchived),
  };

  return (
    <RequestHandler {...bankAccountsQueryProps}>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ handleSubmit, handleReset, setFieldValue, values }) => (
          <>
            <Select
              name='currencyId'
              label='app.sourceCurrency'
              options={transformSelectOptions({
                data: currencies?.currencies,
                label: 'name',
              })}
            />
            <Box mb={'16px'}>
              <Checkbox
                label={'app.archives'}
                onChange={() => setFieldValue('isArchived', !values.isArchived)}
                checked={values.isArchived}
              />
            </Box>
            <Flex justify='space-between'>
              <Button
                onPress={() => {
                  handleReset();
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
              />
              <Button onPress={handleSubmit} intlId='app.apply' />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
