import { MaterialIcons } from '@expo/vector-icons';
import { Box, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { useParams } from 'react-router';

import { ROUTES } from '../../constants';
import { useFiltersContext } from '../../context';
import { useIntersections, useRoute } from '../../hooks';
import { Container } from '../Container/Container';
import { Filters } from '../Filters/Filters';
import { DeleteButton, FloatingActionButton } from '../UI';

export const Layout = ({
  onActionButtonPress,
  actionButtonType = 'add',
  actionButtonIsLoading,
  onRemoveButtonPress,
  isScrollable = true,
  filters,
  children,
  onEndReached,
}) => {
  const { path, pathname } = useRoute();

  const params = useParams();

  const { isFilterOpen, toggleFilter } = useFiltersContext();

  const viewportRef = useRef();
  const inViewport = useIntersections(viewportRef.current, '0px');

  useEffect(() => {
    if (inViewport && onEndReached) onEndReached();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  const showActionButton = typeof onActionButtonPress === 'function';
  const showDeleteButton = typeof onRemoveButtonPress === 'function' && params.id;

  const isFilteredPage =
    ROUTES.isFilteredScreen(path) || ROUTES.isFilteredScreen(pathname);
  const showFilter = isFilteredPage && isFilterOpen && filters;

  return (
    <>
      <Box pt={'30px'} pb={'30px'} flexGrow={1}>
        <Container>
          {showDeleteButton && <DeleteButton onPress={onRemoveButtonPress} />}
          {showActionButton ? (
            <FloatingActionButton
              Icon={() => (
                <MaterialIcons name={actionButtonType} size={25} color='#fff' />
              )}
              onPress={onActionButtonPress}
              isLoading={actionButtonIsLoading}
            />
          ) : null}
          {isScrollable ? (
            <ScrollView scrollEventThrottle={400}>
              {children}
              <View ref={viewportRef} />
            </ScrollView>
          ) : (
            children
          )}
        </Container>
      </Box>
      {showFilter && <Filters onClose={toggleFilter}>{filters}</Filters>}
    </>
  );
};

Layout.propTypes = {
  isScrollable: PropTypes.bool,
  onActionButtonPress: PropTypes.func,
  actionButtonIsLoading: PropTypes.bool,
  actionButtonType: PropTypes.oneOf(['add', 'save']),
  filters: PropTypes.node,
  filtersPanel: PropTypes.node,
  children: PropTypes.node.isRequired,
  onRemoveButtonPress: PropTypes.func,
  onEndReached: PropTypes.func,
  onEndReachedThreshold: PropTypes.number,
};
