import React from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
  TextLink,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useCurrencyContext, useSearchContext } from '../../../context';
import {
  useGetCurrenciesQuery,
  useGetProductsQuery,
  useHasScopesQuery,
  useHistory,
  useIntl,
} from '../../../hooks';
import { getProductsAmount } from '../../../utils';
import ProductMobileItem from './ProductMobileItem/ProductMobileItem';

const Products = () => {
  const navigation = useHistory();
  const intl = useIntl();

  const { data: hasScopesData } = useHasScopesQuery(['Purchase:Read']);

  const { data, fetchMore, ...queryProps } = useGetProductsQuery();

  const { data: currenciesData, ...currenciesQueryProps } = useGetCurrenciesQuery();

  const { currentCurrency } = useCurrencyContext();

  const { debouncedSearchValue } = useSearchContext();

  const type = 'product';

  const fetchMoreData = async (resolve, reject) => {
    await fetchMore({
      variables: {
        search: debouncedSearchValue,
        offset: data.products?.length,
        limit: DATA_REQUEST_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          reject && reject();
          return prev;
        }

        resolve && resolve();

        return {
          products: [...prev?.products, ...fetchMoreResult?.products],
        };
      },
    });
  };

  const handleClickAddButton = () => {
    navigation.push('/warehouse/product/');
  };

  const handleEditButtonClick = (id) => {
    navigation.push(`/warehouse/product/${id}`);
  };

  const getCurrency = (currencyId) => {
    const currency = currenciesData?.currencies?.find((el) => {
      return +el.id === +currencyId;
    });
    return currency?.symbol;
  };

  const getLastRate = (currencyId) => {
    const lastRate = currenciesData?.currencies.find((el) => +el.id === currencyId);
    return lastRate?.lastRate?.ratio;
  };

  const getCostPriceFromTo = (availabilities, currencies) => {
    const sortedCostPriceArr = availabilities
      .map(({ purchase }) => +purchase.price + +purchase.deliveryPrice)
      .sort((a, b) => a - b);
    const min = getProductsAmount(
      sortedCostPriceArr[0],
      type,
      '$',
      currentCurrency === 'default'
        ? { currencySymbol: getCurrency(2) }
        : {
            currencySymbol: currencies?.symbol,
            originalCurrencyRate: getLastRate(2),
            targetCurrencyRate: currencies?.lastRate?.ratio,
          },
    );
    const max = getProductsAmount(
      sortedCostPriceArr[sortedCostPriceArr.length - 1],
      type,
      '$',
      currentCurrency === 'default'
        ? { currencySymbol: getCurrency(2) }
        : {
            currencySymbol: currencies?.symbol,
            originalCurrencyRate: getLastRate('2'),
            targetCurrencyRate: currencies?.lastRate?.ratio,
          },
    );
    return sortedCostPriceArr.length
      ? `${min} - ${max}`
      : `${intl.formatMessage({ id: 'app.notAvailable' })}`;
  };

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...currenciesQueryProps}>
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable fetchMoreData={fetchMoreData}>
              <DesktopTable.Head>
                <DesktopTable.Title intlId='app.title' />
                <DesktopTable.Title intlId='app.sku' />
                <DesktopTable.Title intlId='app.weightKg' />
                {hasScopesData?.hasScopes[0]?.isAllowed && (
                  <DesktopTable.Title intlId='app.costPrice' />
                )}
                <DesktopTable.Title intlId='app.MSRprice' />
                <DesktopTable.Title intlId='app.price' />
                <DesktopTable.Title intlId='app.availabilityCount' />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {data?.products?.map((item, index) => (
                  <DesktopTable.Row
                    key={index}
                    onEditButtonClick={() => handleEditButtonClick(item?.id)}
                  >
                    <DesktopTable.Cell>
                      <TextLink
                        link={`/warehouse/product/${item.id}`}
                        intlId={item?.name}
                      />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.sku}</DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.weight}</DesktopTable.Cell>
                    {hasScopesData?.hasScopes[0]?.isAllowed && (
                      <DesktopTable.Cell>
                        {getCostPriceFromTo(item?.availabilities, currentCurrency)}
                      </DesktopTable.Cell>
                    )}

                    <DesktopTable.Cell>
                      {getProductsAmount(
                        item?.MSRprice,
                        type,
                        getCurrency(item?.MSRpriceCurrencyId),
                        currentCurrency === 'default'
                          ? { currencySymbol: getCurrency(item?.MSRpriceCurrencyId) }
                          : {
                              currencySymbol: currentCurrency?.symbol,
                              originalCurrencyRate: getLastRate(item?.MSRpriceCurrencyId),
                              targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                            },
                      )}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      {getProductsAmount(
                        item?.price,
                        type,
                        getCurrency(item?.priceCurrencyId),
                        currentCurrency === 'default'
                          ? { currencySymbol: getCurrency(item?.priceCurrencyId) }
                          : {
                              currencySymbol: currentCurrency?.symbol,
                              originalCurrencyRate: getLastRate(item?.priceCurrencyId),
                              targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                            },
                      )}
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>{item?.quantity}</DesktopTable.Cell>
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <MobileTable
              data={data?.products}
              fetchMoreData={fetchMoreData}
              renderItem={(item) => (
                <ProductMobileItem
                  onItemPress={handleEditButtonClick}
                  id={item?.id}
                  name={item?.name}
                  articul={item?.sku}
                  weight={item?.weight}
                  costPrice={getCostPriceFromTo(item?.availabilities, currentCurrency)}
                  MSRprice={getProductsAmount(
                    item?.MSRprice,
                    type,
                    getCurrency(item?.MSRpriceCurrencyId),
                    currentCurrency === 'default'
                      ? { currencySymbol: getCurrency(item?.MSRpriceCurrencyId) }
                      : {
                          currencySymbol: currentCurrency?.symbol,
                          originalCurrencyRate: getLastRate(item?.MSRpriceCurrencyId),
                          targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                        },
                  )}
                  price={getProductsAmount(
                    item?.price,
                    type,
                    getCurrency(item?.priceCurrencyId),
                    currentCurrency === 'default'
                      ? { currencySymbol: getCurrency(item?.priceCurrencyId) }
                      : {
                          currencySymbol: currentCurrency?.symbol,
                          originalCurrencyRate: getLastRate(item?.priceCurrencyId),
                          targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                        },
                  )}
                  quantity={item?.quantity}
                  hasScope={hasScopesData?.hasScopes[0]?.isAllowed}
                />
              )}
            />
          )}
        </RequestHandler>
      </RequestHandler>
    </Layout>
  );
};

export default Products;
