import { gql } from '@apollo/client';

import { FRAGMENT_PRODUCT, FRAGMENT_STOCK_PLACE } from './fragments';

export const ORDER_MUTATION = gql`
  mutation order($input: OrderInput!) {
    order(input: $input) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        middleName
        emails {
          id
          email
        }
        phones {
          id
          phone
        }
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      orderItems {
        id
        pricePerPcs
        quantity
        product {
          id
          name
          url
        }
      }
      discount
      description
      deliveryInformation {
        id
        address
        comment
        ttn
        city {
          id
          name
          region {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
      shippingType {
        id
        name
      }
      paymentType {
        id
        name
      }
      paymentStatus {
        id
        name
      }
    }
  }
`;

export const CUSTOMER_MUTATION = gql`
  mutation customer($input: CustomerInput!) {
    customer(input: $input) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
      children {
        id
        name
        birthday
        sex
        relationType {
          id
          name
        }
      }
      orders {
        id
        createdAt
        source {
          id
          name
        }
        status {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
      }
    }
  }
`;

export const PRODUCT_MUTATION = gql`
  ${FRAGMENT_PRODUCT}
  mutation ($input: ProductInput!) {
    product(input: $input) {
      ...FragmentProduct
    }
  }
`;

export const STOCK_PLACE_MUTATION = gql`
  ${FRAGMENT_STOCK_PLACE}
  mutation ($input: StockPlaceInput!) {
    stockPlace(input: $input) {
      ...FragmentStockPlace
    }
  }
`;

export const USER_MUTATION = gql`
  mutation user($input: UserInput!) {
    user(input: $input) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      status
      email
      phone
      skype
      roles {
        id
        name
      }
    }
  }
`;

export const ADD_PURCHASE_MUTATION = gql`
  mutation purchase($input: PurchaseInput!) {
    purchase(input: $input) {
      id
      product {
        id
        name
        price
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
        status {
          id
          name
        }
      }
      forwarding {
        id
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
      }
      price
      deliveryPrice
      purchaseTime
      quantity
    }
  }
`;

export const AVAILABILITY_MOVE_MUTATION = gql`
  mutation availability(
    $fromPlaceId: Int!
    $purchaseId: Int!
    $fromConditionId: Int!
    $toPlaceId: Int!
    $toConditionId: Int!
    $value: Int!
  ) {
    availability {
      move(
        fromPlaceId: $fromPlaceId
        purchaseId: $purchaseId
        fromConditionId: $fromConditionId
        toPlaceId: $toPlaceId
        toConditionId: $toConditionId
        value: $value
      ) {
        old {
          id
        }
        new {
          id
        }
      }
    }
  }
`;

export const AVAILABILITY_REMOVE_MUTATION = gql`
  mutation availability(
    $placeId: Int!
    $purchaseId: Int!
    $conditionId: Int!
    $value: Int!
  ) {
    availability {
      remove(
        placeId: $placeId
        purchaseId: $purchaseId
        conditionId: $conditionId
        value: $value
      ) {
        id
      }
    }
  }
`;

export const AVAILABILITY_ADD_MUTATION = gql`
  mutation availability(
    $placeId: Int!
    $purchaseId: Int!
    $conditionId: Int!
    $value: Int!
  ) {
    availability {
      add(
        placeId: $placeId
        purchaseId: $purchaseId
        conditionId: $conditionId
        value: $value
      ) {
        id
      }
    }
  }
`;

export const ADD_PURCHASE_PARCEL_MUTATION = gql`
  mutation ($input: PurchaseParcelInput!) {
    purchaseParcel(input: $input) {
      id
      trackingCode
      date
      forwardings {
        id
        trackingCode
      }
      status {
        id
        name
      }
      source {
        id
        name
      }
      purchases {
        id
        quantity
        product {
          id
          length
          width
          height
          weight
        }
      }
    }
  }
`;

export const ADD_PURCHASE_FORWARDING_MUTATION = gql`
  mutation ($input: PurchaseForwardingInput!) {
    purchaseForwarding(input: $input) {
      id
      date
      trackingCode
      deliveryPrice
      parcels {
        id
        trackingCode
      }
      status {
        id
        name
      }
      purchases {
        id
        quantity
        product {
          id
          length
          width
          height
          weight
        }
      }
    }
  }
`;

export const ADD_CURRENCY_MUTATION = gql`
  mutation ($input: CurrencyInput!) {
    currency(input: $input) {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
      }
      lastRate {
        id
        ratio
        date
        dateTo
      }
    }
  }
`;

export const ADD_TRANSACTION_MUTATION = gql`
  mutation ($input: TransactionInput!) {
    transaction(input: $input) {
      id
      date
      amount
      comment
      orderId
      userId
      purchaseId
      forwardingId
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currency {
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }
      user {
        id
        firstName
        lastName
      }
      purchase {
        id
        purchaseTime
      }
      order {
        id
        createdAt
      }
      forwarding {
        id
        trackingCode
      }
    }
  }
`;

export const DELETE_TRANSACTION_MUTATION = gql`
  mutation ($id: Int!) {
    deleteTransaction(id: $id)
  }
`;

export const ADD_EXCHANGE_MUTATION = gql`
  mutation ($input: ExchangeInput!) {
    exchange(input: $input) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const UPDATE_PURCHASES_MUTATION = gql`
  mutation ($input: PurchasesInput!) {
    purchases(input: $input) {
      id
      price
      quantity
      deliveryPrice
      parcel {
        id
        trackingCode
      }
      forwarding {
        id
      }
      product {
        id
        length
        width
        height
        weight
        name
      }
    }
  }
`;
export const ADD_PACKAGING_TEMPLATE = gql`
  mutation ($input: PackagingTemplateInput!) {
    packagingTemplate(input: $input) {
      id
      name
      width
      length
      height
      weight
    }
  }
`;

export const DELETE_PACKAGING_TEMPLATE = gql`
  mutation ($id: Int!) {
    deletePackagingTemplate(id: $id)
  }
`;

export const DELETE_PURCHASE = gql`
  mutation ($id: Int!) {
    deletePurchase(id: $id)
  }
`;

export const ADD_PHOTO_MUTATION = gql`
  mutation ($input: PhotoInput!) {
    photo(input: $input) {
      id
      path
    }
  }
`;

export const ADD_FORMATTED_PHOTO = gql`
  mutation ($input: FormattedPhotoInput) {
    formattedPhoto(input: $input) {
      photoId
      format
      pathWithTime
      width
      height
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation bankAccount($input: BankAccountInput!) {
    bankAccount(input: $input) {
      id
      title
      description
      currencyId
      currency {
        id
        name
        code
        symbol
        lastRate {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($id: ID!) {
    deleteBankAccount(id: $id)
  }
`;
