import { useFormikContext } from 'formik';
import { Center } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useSearchContext } from '../../context';
import { useGetUsersQuery } from '../../hooks';
import { getNameInitials } from '../../utils';
import {
  AddModal,
  DesktopTable,
  ModalButton,
  RequestHandler,
  Typography,
  UserCard,
} from '../index';

export const AddUserModal = ({ isOpen, onClose }) => {
  const { searchValue, changeSearchValue } = useSearchContext();
  const { values, setFieldValue } = useFormikContext();

  const { data, ...queryProps } = useGetUsersQuery({
    searchValue,
    skip: !searchValue,
  });

  const handleSet = (item) => {
    setFieldValue('user', item);
    onClose();
  };

  return (
    <AddModal
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onChangeSearchValue={changeSearchValue}
      queryProps={queryProps}
      withScan={false}
      title='app.user'
    >
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <>
            <DesktopTable inModal>
              <DesktopTable.Body>
                {data?.filteredUsers?.map((item) => (
                  <DesktopTable.Row key={item.id}>
                    <DesktopTable.Cell maxWidth={45} width={45}>
                      <Center
                        width='40px'
                        height='40px'
                        borderRadius='20px'
                        bgColor='success'
                        mr='md'
                      >
                        <Typography color='gray.extraLight'>
                          {getNameInitials(`${item.lastName} ${item.firstName}`)}
                        </Typography>
                      </Center>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography intlId={`${item.lastName} ${item.firstName}`} />
                    </DesktopTable.Cell>
                    <ModalButton
                      item={item}
                      addedItemsIds={values?.user?.id}
                      handleSet={handleSet}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          </>
        ) : (
          <>
            {data?.filteredUsers?.map((item, index) => (
              <UserCard key={index} item={item} handleSet={handleSet} />
            ))}
          </>
        )}
      </RequestHandler>
    </AddModal>
  );
};

AddUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
