import { endOfDay, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { Flex } from 'native-base';
import React from 'react';

import {
  Button,
  Checkbox,
  DatePicker,
  RequestHandler,
  Typography,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useFilter, useGetTransactionsTypesQuery } from '../../../hooks';

export const TransactionsFilters = () => {
  const { filters, setFilters, clearFilters } = useFilter();
  const { data, ...queryProps } = useGetTransactionsTypesQuery();
  const { toggleFilter } = useFiltersContext();

  const handleFormSubmit = (values) => {
    setFilters({
      dateFrom: values?.dateFrom ? startOfDay(new Date(values?.dateFrom)) : '',
      dateTo: values?.dateTo ? endOfDay(new Date(values?.dateTo)) : '',
      typeIds: values.typeIds,
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const handleResetForm = (setFieldValue) => {
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');
    setFieldValue('typeIds', []);
    clearFilters();
  };

  const handleCheckboxChange = (id, typeIds, setFieldValue) => {
    if (typeIds.includes(id)) {
      setFieldValue(
        'typeIds',
        typeIds.filter((el) => el !== id),
      );
    } else {
      setFieldValue('typeIds', [...typeIds, id]);
    }
  };

  const initialValues = {
    dateFrom: filters?.dateFrom || '',
    dateTo: filters?.dateTo || '',
    typeIds: Array.isArray(filters?.typeIds)
      ? filters?.typeIds
      : [filters?.typeIds] || [],
  };

  return (
    <RequestHandler {...queryProps}>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ handleSubmit, values, handleReset, setFieldValue }) => (
          <>
            <DatePicker name='dateFrom' label='date.from' />
            <DatePicker name='dateTo' label='date.to' />
            <Typography intlId='app.typeOfTransaction' mb='15px' fontSize='16px' />
            {data?.transactionTypes?.map((item) => (
              <Checkbox
                key={+item.id}
                checked={values.typeIds.includes(+item.id)}
                onChange={() =>
                  handleCheckboxChange(+item.id, values.typeIds, setFieldValue)
                }
                label={item.name}
                mb='10px'
              />
            ))}
            <Flex justify='space-between' mt='20px'>
              <Button
                onPress={() => {
                  handleReset();
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
              />
              <Button onPress={handleSubmit} intlId='app.apply' />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
