/* eslint-disable react-native/no-inline-styles */
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';
import { Title } from 'react-native-paper';

import { AddProductModal, Button, DesktopTable } from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import styles from '../Style';
import OrderItem from './OrderItem';
import OrderItemMobile from './OrderItemMobile';

const OrderItems = ({ statuses = [] }) => {
  const intl = useIntl();

  const { values } = useFormikContext();

  const { source = {}, orderItems = [], status } = values;

  const { reservationType } = statuses.find(({ id }) => +id === +status) || {};

  const [isReservationCanceled, setIsReservationCanceled] = useState(
    !(reservationType === 'reserved'),
  );

  const isStatusReserved =
    reservationType && reservationType === 'reserved' && !isReservationCanceled;

  const hasItemConditions = reservationType && reservationType !== 'none';

  const handleAddProduct = (arrayHelpers) => (product) => {
    arrayHelpers.push({
      pricePerPcs: (product?.source?.price).toFixed(2) || '',
      quantity: 1,
      product,
    });
  };

  const countTotalPrice = () => {
    let result = 0;
    orderItems?.map((orderItem) => {
      result += orderItem.pricePerPcs * orderItem.quantity || 0;
    });

    return result.toFixed(2);
  };

  return (
    <View>
      <FieldArray
        name='orderItems'
        render={(arrayHelpers) => (
          <>
            <View style={styles.blockHeader}>
              <View style={[styles.orderItemHeader, stylesGlobal.title]}>
                <Title>
                  {intl.formatMessage({
                    id: 'order.items',
                  })}
                </Title>
                {!isStatusReserved && (
                  <AddProductModal
                    currencyRatio={{ id: 1 }}
                    source={source}
                    hasError={!source}
                    errorText='app.chooseSource'
                    buttonText='app.addProduct'
                    onAddProduct={handleAddProduct(arrayHelpers)}
                    isMultiSelect
                    withQuantity
                    withPrice
                    addedProducts={orderItems?.map((el) => el?.product)}
                  />
                )}
                {isStatusReserved && (
                  <Button
                    style={styles.reservationCancelButton}
                    onPress={() => setIsReservationCanceled(true)}
                    intlId='app.reservationCancel'
                  />
                )}
              </View>
            </View>
            {IS_DESKTOP_SCREEN ? (
              <DesktopTable editable={false}>
                <DesktopTable.Head>
                  <DesktopTable.Title intlId='app.title' />
                  <DesktopTable.Title intlId='app.price' />
                  <DesktopTable.Title intlId='app.quantity' textAlign={'center'} />
                  {hasItemConditions && (
                    <DesktopTable.Title intlId='app.condition' textAlign={'center'} />
                  )}
                  <DesktopTable.Title intlId='app.sum' textAlign={'center'} />
                  <DesktopTable.Title />
                </DesktopTable.Head>
                <DesktopTable.Body>
                  {orderItems?.map((orderItem, i) => (
                    <OrderItem
                      key={i}
                      index={i}
                      onDelete={() => arrayHelpers.remove(i)}
                      orderItem={orderItem}
                      isStatusReserved={isStatusReserved}
                      hasItemConditions={hasItemConditions}
                    />
                  ))}
                </DesktopTable.Body>
              </DesktopTable>
            ) : (
              orderItems?.map((orderItem, i) => (
                <OrderItemMobile
                  key={i}
                  index={i}
                  onDelete={() => arrayHelpers.remove(i)}
                  orderItem={orderItem}
                  isStatusReserved={isStatusReserved}
                  hasItemConditions={hasItemConditions}
                />
              ))
            )}
            <Text style={styles.boxTotalPrice}>
              {countTotalPrice()} {intl.formatMessage({ id: 'currency.uah' })}
            </Text>
          </>
        )}
      />
    </View>
  );
};

OrderItems.propTypes = {
  statuses: PropTypes.array,
  currencyRatio: PropTypes.object,
};

export default OrderItems;
