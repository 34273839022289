import * as yup from 'yup';

import {
  INCORRECT_PHONE_MESSAGE,
  INCORRECT_PHONE_OR_EMAIL,
  INVALID_EMAIL_MESSAGE,
  MINIMUM_VALUE_MESSAGE_0_1,
  MINIMUM_VALUE_MESSAGE_1,
  REQUIRE_CHARACTERS,
  REQUIRE_CONTACT,
  REQUIRE_CUSTOMER,
  REQUIRE_MESSAGE,
} from './messages';

const phoneValidation = yup
  .string()
  .matches(/\+\d{12}/, INCORRECT_PHONE_MESSAGE)
  .max(13, INCORRECT_PHONE_MESSAGE)
  .min(13, INCORRECT_PHONE_MESSAGE);

const phoneOrEmailValidation = yup
  .string()
  .matches(
    /^(?:\+\d{12}|[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z])$/,
    INCORRECT_PHONE_OR_EMAIL,
  )
  .required(REQUIRE_CONTACT);

export const addProductSchema = yup.object().shape({
  name: yup.string().required(REQUIRE_MESSAGE),
  sku: yup.string().required(REQUIRE_MESSAGE),
  width: yup.number().required(REQUIRE_MESSAGE),
  weight: yup.number().required(REQUIRE_MESSAGE),
  height: yup.number().required(REQUIRE_MESSAGE),
  length: yup.number().required(REQUIRE_MESSAGE),
  price: yup.number().required(REQUIRE_MESSAGE),
  priceCurrencyId: yup.number().nullable().required(REQUIRE_MESSAGE),
  MSRprice: yup.number().required(REQUIRE_MESSAGE),
  MSRpriceCurrencyId: yup.number().required(REQUIRE_MESSAGE),
  barcodes: yup.array().of(yup.number()),
});

export const addBoxSchema = yup.object().shape({
  name: yup.string().required(REQUIRE_MESSAGE),
  room: yup.string().nullable().required(REQUIRE_MESSAGE),
  shelving: yup.string().nullable().required(REQUIRE_MESSAGE),
});

export const displacementsSchema = yup.object().shape({
  fromPlace: yup.object().nullable().required(REQUIRE_MESSAGE),
  toPlace: yup.string().nullable().required(REQUIRE_MESSAGE),
  availabilities: yup.array().of(
    yup.object().shape({
      fromConditionId: yup.string().required(REQUIRE_MESSAGE),
      toConditionId: yup.string().required(REQUIRE_MESSAGE),
      purchaseId: yup.string().required(REQUIRE_MESSAGE),
      quantity: yup.string().required(REQUIRE_MESSAGE),
    }),
  ),
});

export const annulSchema = yup.object().shape({
  fromPlace: yup.object().nullable().required(REQUIRE_MESSAGE),
});

export const acceptanceSchema = (isNewPlace) => {
  const fields = {
    forwardingId: yup.string().nullable().required(REQUIRE_MESSAGE),
    products: yup.array().of(
      yup.object().shape({
        conditionId: yup.string().required(REQUIRE_MESSAGE),
        purchaseId: yup.string().nullable().required(REQUIRE_MESSAGE),
        quantity: yup.string().required(REQUIRE_MESSAGE),
      }),
    ),
  };
  if (isNewPlace) {
    fields.name = yup.string().required(REQUIRE_MESSAGE);
    fields.room = yup.string().nullable().required(REQUIRE_MESSAGE);
    fields.shelving = yup.string().nullable().required(REQUIRE_MESSAGE);
  }
  if (!isNewPlace) {
    fields.place = yup.object().nullable().required(REQUIRE_MESSAGE);
  }
  return yup.object().shape({ ...fields });
};

export const exchangeSchema = yup.object().shape({
  date: yup.string().required(REQUIRE_MESSAGE),
  fromAmount: yup.string().required(REQUIRE_MESSAGE),
  fromBankAccountId: yup.string().required(REQUIRE_MESSAGE),
  toAmount: yup.string().required(REQUIRE_MESSAGE),
  toBankAccountId: yup.string().required(REQUIRE_MESSAGE),
  comment: yup.string(),
});

export const userSchema = yup.object().shape({
  id: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  middleName: yup.string(),
  birthday: yup.string(),
  sex: yup.string(),
  status: yup.string().required(REQUIRE_MESSAGE),
  roles: yup.array().required(REQUIRE_MESSAGE),
  skype: yup.string(),
  phone: phoneValidation,
  email: yup.string().email().required(REQUIRE_MESSAGE),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email(INVALID_EMAIL_MESSAGE).required(REQUIRE_MESSAGE),
  password: yup.string().required(REQUIRE_MESSAGE),
});

export const customerSchema = yup.object().shape({
  id: yup.string(),
  firstName: yup.string().required(REQUIRE_MESSAGE),
  lastName: yup.string(),
  middleName: yup.string(),
  birthday: yup.string(),
  sex: yup.string(),
  contacts: yup.array().of(phoneOrEmailValidation),
  children: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string().required(REQUIRE_MESSAGE),
      birthday: yup.string(),
      sex: yup.string().required(REQUIRE_MESSAGE),
      relationType: yup.string(),
    }),
  ),
});

export const addPurchaseSchema = yup.object().shape({
  product: yup.object(),
  deliveryPrice: yup.string(),
  quantity: yup.string().required(REQUIRE_MESSAGE),
  price: yup.string().required(REQUIRE_MESSAGE),
  parcel: yup.object().nullable(),
  forwarding: yup.object().nullable(),
});

export const currencySchema = yup.object().shape({
  name: yup.string().required(REQUIRE_MESSAGE),
  code: yup
    .string()
    .test('length', REQUIRE_CHARACTERS, (val) => val?.length === 3)
    .required(REQUIRE_MESSAGE),
  symbol: yup.string().required(REQUIRE_MESSAGE),
  ratio: yup.string().required(REQUIRE_MESSAGE),
  date: yup.string().required(REQUIRE_MESSAGE),
});

export const addParcelSchema = yup.object().shape({
  source: yup.string().required(REQUIRE_MESSAGE),
  trackingCode: yup.string().required(REQUIRE_MESSAGE),
  status: yup.string().required(REQUIRE_MESSAGE),
  date: yup.string().required(REQUIRE_MESSAGE),
});

export const accountSchema = yup.object().shape({
  title: yup.string().required(REQUIRE_MESSAGE),
  currencyId: yup.string().required(REQUIRE_MESSAGE),
  description: yup.string().required(REQUIRE_MESSAGE),
});

export const transactionSchema = (typeId) => {
  const fields = {
    date: yup.string().required(REQUIRE_MESSAGE),
    typeId: yup.string().required(REQUIRE_MESSAGE),
    amount: yup.string().required(REQUIRE_MESSAGE),
    bankAccountId: yup.string().required(REQUIRE_MESSAGE),
  };

  if (typeId === '7') {
    fields.forwarding = yup.object().required(REQUIRE_MESSAGE);
  }
  if (typeId === '6') {
    fields.purchase = yup.object().required(REQUIRE_MESSAGE);
  }
  if (typeId === '1' || typeId === '3' || typeId === '8') {
    fields.order = yup.object().required(REQUIRE_MESSAGE);
  }
  if (typeId === '4' || typeId === '5') {
    fields.user = yup.object().required(REQUIRE_MESSAGE);
  }

  return yup.object().shape({ ...fields });
};

export const addForwardingSchema = yup.object().shape({
  trackingCode: yup.string().required(REQUIRE_MESSAGE),
  status: yup.string().required(REQUIRE_MESSAGE),
  date: yup.string().required(REQUIRE_MESSAGE),
  deliveryPrice: yup.string(),
  parcels: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.number().positive().integer().nullable(),
        trackingCode: yup.string(),
      })
      .noUnknown(),
  ),
});

export const addPackagingTemplateSchema = yup.object().shape({
  name: yup.string().required(REQUIRE_MESSAGE),
  width: yup.number().required(REQUIRE_MESSAGE).min(1, MINIMUM_VALUE_MESSAGE_1),
  height: yup.number().required(REQUIRE_MESSAGE).min(1, MINIMUM_VALUE_MESSAGE_1),
  length: yup.number().required(REQUIRE_MESSAGE).min(1, MINIMUM_VALUE_MESSAGE_1),
  weight: yup.number().required(REQUIRE_MESSAGE).min(0.1, MINIMUM_VALUE_MESSAGE_0_1),
});

export const orderSchema = yup.object().shape({
  status: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  user: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  source: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  paymentType: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  paymentStatus: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  shippingType: yup.number().positive().integer().required(REQUIRE_MESSAGE),
  orderItems: yup.array().of(
    yup.object().shape({
      quantity: yup.number().positive().integer(),
      product: yup.object().shape({
        id: yup.number().positive().integer().nullable(),
        name: yup.string().nullable(),
        url: yup.string().nullable(),
      }),
      pricePerPcs: yup.number().positive(),
      availability: yup.object().shape({
        allowedConditions: yup.array().min(1),
      }),
    }),
  ),
  customer: yup
    .object()
    .shape({
      id: yup
        .string()
        .when('contacts', {
          is: (val) => !val?.length,
          then: yup.string().required(REQUIRE_CUSTOMER),
        })
        .nullable(),
      firstName: yup.string().required(REQUIRE_MESSAGE),
      lastName: yup.string().required(REQUIRE_MESSAGE),
      middleName: yup.string(),
      contacts: yup.array().of(phoneOrEmailValidation),
    })
    .nullable(),
  description: yup.string(),
});
