/* eslint-disable react-native/no-inline-styles */
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { Select } from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';

const PaymentProps = ({ paymentTypes, paymentStatuses }) => {
  const intl = useIntl();

  return (
    <>
      <Title style={stylesGlobal.title}>
        {intl.formatMessage({ id: 'app.payment' })}
      </Title>
      <View style={stylesGlobal.formWrapper}>
        <View style={IS_DESKTOP_SCREEN && { width: '30%' }}>
          <Select
            name='paymentStatus'
            label={'app.paymentStatus'}
            options={transformSelectOptions({
              data: paymentStatuses,
              label: 'name',
            })}
          />
        </View>
        <View style={IS_DESKTOP_SCREEN && { width: '65%' }}>
          <Select
            name='paymentType'
            label={'app.paymentOption'}
            options={transformSelectOptions({
              data: paymentTypes,
              label: 'name',
            })}
          />
        </View>
      </View>
    </>
  );
};

PaymentProps.propTypes = {
  paymentTypes: PropTypes.array.isRequired,
  paymentStatuses: PropTypes.array.isRequired,
};

export default PaymentProps;
