export const getReverseCourseValue = (ratio) => {
  if (ratio > 0) {
    return Number(1 / ratio).toFixed(1);
  }
  return '';
};

export const getCourseValue = (reverseCourse) => {
  if (reverseCourse > 0) {
    return Number(1 / reverseCourse).toFixed(5);
  }
  return '';
};
