export const REQUIRE_MESSAGE = 'validation.required';
export const MINIMUM_VALUE_MESSAGE_0_1 = 'validation.minOneTenth';
export const MINIMUM_VALUE_MESSAGE_1 = 'validation.minOne';
export const INCORRECT_PHONE_MESSAGE = 'validation.incorrectPhone';
export const INVALID_EMAIL_MESSAGE = 'validation.incorrectEmail';
export const INCORRECT_TTN = 'validation.incorrectTTN';
export const REQUIRE_PHONE = 'validation.onePhoneIsRequired';
export const REQUIRE_CUSTOMER = 'app.chooseCustomer';
export const INCORRECT_PHONE_OR_EMAIL = 'validation.incorrectPhoneOrEmail';
export const REQUIRE_CONTACT = 'validation.oneContactIsRequired';
export const REQUIRE_CHARACTERS = 'validation.mustBe3Characters';
