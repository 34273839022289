export const theme = {
  colors: {
    primary: '#47C8FF',
    darkGray: '#4F4F4F',
    blueDark: '#2C3E61',
    dark: '#222222',
    black: '#000000',
    lightGray: '#F7F7F7',
    gray: '#828282',
    mediumGray: '#BDBDBD',
    white: '#ffffff',
    lightOverlay: 'rgba(255, 255, 255, 0.4)',
    danger: '#dc3545',
    conditions: {
      1: '#22CE48',
      2: '#8DCE22',
      3: '#B6CE22',
      4: '#CEC722',
      5: '#CEB322',
      6: '#CE9422',
      7: '#CE7522',
      8: '#CE6022',
      9: '#CE4C22',
      10: '#CE2222',
    },
    text: {
      greyLight: '#f2f2f2',
    },
  },
  spaces: {
    default: 16,
    medium: 18,
    large: 24,
  },
  fonts: {
    light: 'roboto-light',
    regular: 'roboto-regular',
    medium: 'roboto-medium',
  },
};
