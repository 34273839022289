import { Box, FormControl } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { FormControlError } from '../FormControlError/FormControlError';
import { FormControlLabel } from '../FormControlLabel/FormControlLabel';

export const FieldWrapper = ({
  label,
  isLight,
  errorMessageText,
  isInvalid,
  children,
  style,
  width,
  mb,
  pl,
  pr,
  ...props
}) => {
  return (
    <Box
      pl={pl}
      pr={pr}
      style={style}
      width={{ base: 'auto', md: width }}
      maxWidth={width}
      mb={{ base: 'md', sm: mb !== undefined ? mb : 'md' }}
      {...props}
    >
      <FormControl isInvalid={isInvalid}>
        <FormControlLabel label={label} isLight={isLight} />
        {children}
        <FormControlError errorMessageText={errorMessageText} />
      </FormControl>
    </Box>
  );
};

FieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  isLight: PropTypes.bool,
  errorMessageText: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
