// import { REACT_APP_BASE_URL, REACT_APP_UPLOAD_URL } from '@env';
import { Dimensions, Platform } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export const drawerWidth = 220;
export const limitOrders = 50;
export const limitCustomers = 50;
export const limitUsers = 20;
export const displayWidth = 700;
export const uploadURL = 'https://crm.demo.malevichstudio.com';
export const isWeb = Platform.OS === 'web';
export const isApp = Platform.OS !== 'web';
export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';

export const API_HOST = 'https://crm.demo.malevichstudio.com';
export const IS_WEB = Platform.OS === 'web';
export const IS_APP = Platform.OS !== 'web';
export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';
export const DEVICE_WIDTH = Dimensions.get('window').width;
export const DEVICE_HEIGHT = Dimensions.get('window').height;
export const IS_MOBILE_SCREEN = DEVICE_WIDTH <= 576;
export const IS_LAPTOP_SCREEN = DEVICE_WIDTH <= 992 && DEVICE_WIDTH > 576;
export const IS_DESKTOP_SCREEN = DEVICE_WIDTH > 992;
export const STATUS_BAR_HEIGHT = getStatusBarHeight();
export const LIMIT_CUSTOMERS = 50;
export const LIMIT_ORDERS = 50;
export const LIMIT_USERS = 50;
export const DATA_REQUEST_LIMIT = 10;

export const FORWARDING_STATUS_IDS = {
  onTheWay: '1',
  inWarehouse: '2',
};

export const PARCEL_STATUS_IDS = {
  onTheWay: '1',
  inWarehouse: '2',
};

export const FLOAT_NUMBER_REGEX = /[^0-9.]/g;
