import { Box, Center, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { FormattedDate } from '../FormattedDate/FormattedDate';
import { ClockIcon } from '../Icons';
import { TextLink } from '../TextLink/TextLink';

export const LinkDescription = ({
  icon,
  intlId,
  date,
  link,
  dateWithFrom,
  dateFormat = 'd.MM.y HH:mm',
  vertically = false,
  withClockIcon = false,
  ...props
}) => {
  const hasDate = date && date !== 0;

  const DescriptionDate = () => {
    return (
      <FormattedDate
        format={dateFormat}
        ml={vertically ? 0 : 2}
        pt={'3px'}
        intlId={dateWithFrom && 'app.from'}
        fontSize='12px'
        fontWeight='400'
        color='#828282'
        date={date}
      />
    );
  };

  return (
    <Flex
      align={'flex-start'}
      direction={vertically ? 'column' : 'row'}
      {...(!icon && !vertically ? { paddingY: '8px' } : null)}
      {...props}
    >
      <Flex>
        {icon && (
          <Center width='15px' height='15px' mr={1}>
            {React.cloneElement(icon, { fill: '#47C8FF', size: '15px' })}
          </Center>
        )}
        {link && intlId && (
          <TextLink underline intlId={vertically ? `${intlId} \n` : intlId} link={link} />
        )}
      </Flex>
      {hasDate &&
        (withClockIcon ? (
          <Flex>
            <Box pt={'3.5px'} pr={'4px'}>
              <ClockIcon />
            </Box>
            <DescriptionDate />
          </Flex>
        ) : (
          <DescriptionDate />
        ))}
    </Flex>
  );
};

LinkDescription.propTypes = {
  icon: PropTypes.elementType,
  intlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string.isRequired,
  dateWithFrom: PropTypes.bool,
  vertically: PropTypes.bool,
  withClockIcon: PropTypes.bool,
  dateFormat: PropTypes.string,
};
