import { useField } from 'formik';
import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { FieldWrapper } from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { getPhoneOrEmail } from '../../../utils';

export const CustomerContact = ({ name, width, removeContact, index }) => {
  const [field, meta, { setValue }] = useField(name);
  const intl = useIntl();

  const hasError = Boolean(meta.error && meta.touched);
  return (
    <FieldWrapper
      isInvalid={hasError}
      label={getPhoneOrEmail(field?.value) || ' '}
      errorMessageText={meta.error}
      width={width || (IS_MOBILE_SCREEN ? '100%' : '50%')}
      pr='12px'
      pl='12px'
    >
      <Input
        value={field?.value}
        onChangeText={(value) => {
          if (!value && index !== 0) {
            removeContact(index);
          } else setValue(value);
        }}
        placeholder={intl.formatMessage({ id: 'app.phoneOrEmail' })}
        height={'33px'}
      />
    </FieldWrapper>
  );
};

CustomerContact.propTypes = {
  name: PropTypes.string.isRequired,
  removeContact: PropTypes.func.isRequired,
  width: PropTypes.string,
  index: PropTypes.number,
};
