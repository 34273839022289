import React from 'react';
import { useHistory } from 'react-router';

import { DesktopTable, Layout, MobileTable, RequestHandler } from '../../../components';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import { useGetPackagingTemplates } from '../../../hooks';
import PackagingTemplatesMobile from './PackagingTemplatesMobile/PackagingTemplatesMobile';

const COLUMNS = [
  { intlId: 'app.name' },
  { intlId: 'app.widthCm' },
  { intlId: 'app.lengthCm' },
  { intlId: 'app.heightCm' },
  { intlId: 'app.weightKg' },
];

const PackagingTemplates = () => {
  const history = useHistory();

  const { data, ...queryProps } = useGetPackagingTemplates();

  const handleEditButtonClick = (id) => {
    history.push(`/settings/packagingtemplate/${id}`);
  };

  const handleClickAddButton = () => {
    history.push('/settings/packagingtemplate/');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...queryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.packagingTemplates?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  onEditButtonClick={() => handleEditButtonClick(item.id)}
                >
                  <DesktopTable.Cell>{item.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.width / 100}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.length / 100}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.height / 100}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.weight / 1000}</DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }

  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...queryProps}>
        {/* <Typography ml={'md'} mb='lg' color='dark' fontSize='lg' intlId='app.boxLayout' /> */}

        <MobileTable
          data={data?.packagingTemplates?.map((item) => ({
            ...item,
            width: item?.width / 100,
            height: item?.height / 100,
            length: item?.length / 100,
            weight: item?.weight / 1000,
          }))}
          renderItem={(item) => (
            <PackagingTemplatesMobile
              data={item}
              rows={COLUMNS}
              handleEditButtonClick={() => handleEditButtonClick(item?.id)}
            />
          )}
          handleEndReached={(resolve) => {
            resolve();
          }}
        />
      </RequestHandler>
    </Layout>
  );
};

PackagingTemplates.propTypes = {};

export default PackagingTemplates;
