import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Title } from 'react-native-paper';

import { TextField } from '../../../../components';
import stylesGlobal from '../../../../globalStyles';

const DescriptionProps = ({ name }) => {
  const intl = useIntl();

  return (
    <>
      <Title style={stylesGlobal.title}>
        {intl.formatMessage({
          id: 'app.managerComment',
        })}
      </Title>
      <TextField name={name} />
    </>
  );
};

DescriptionProps.propTypes = {
  name: PropTypes.string,
};

export default DescriptionProps;
