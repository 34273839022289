import { MaterialIcons } from '@expo/vector-icons';
import { Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DesktopTable, TextField, TextLink, Typography } from '../../../../components';
import { useIntl } from '../../../../hooks';
import { ItemConditionField, ItemLocation } from '.';

const OrderItem = ({
  onDelete,
  index,
  orderItem,
  isStatusReserved,
  hasItemConditions,
}) => {
  const intl = useIntl();

  const [showLocation, setShowLocation] = useState(false);

  return (
    <>
      <DesktopTable.Row>
        <DesktopTable.Cell border='none' width={'260px'} maxWidth={'260px'}>
          <TextLink link={`/warehouse/product/${orderItem?.product?.id}`}>
            {orderItem?.product?.name}
          </TextLink>
        </DesktopTable.Cell>
        {!isStatusReserved ? (
          <>
            <DesktopTable.Cell border='none' width={'90px'}>
              <Flex>
                <TextField
                  type={'number'}
                  width={'60px'}
                  mb={0}
                  mr={'5px'}
                  name={`orderItems.${index}.pricePerPcs`}
                />
                <Typography mb={'10px'} intlId='currency.uah' />
              </Flex>
            </DesktopTable.Cell>
            <DesktopTable.Cell border='none' textAlign={'center'} width={'90px'}>
              <Flex justifyContent={'center'}>
                <TextField
                  mb={0}
                  width={'60px'}
                  type={'number'}
                  inputProps={{ textAlign: 'center' }}
                  name={`orderItems.${index}.quantity`}
                />
              </Flex>
            </DesktopTable.Cell>
            {hasItemConditions && (
              <DesktopTable.Cell border='none' textAlign={'center'} width={'90px'}>
                <ItemConditionField
                  index={index}
                  availableConditions={orderItem?.product?.avaliableConditions}
                  condition={orderItem?.availability?.condition}
                  readOnly={isStatusReserved}
                  setShowLocation={setShowLocation}
                  showLocation={showLocation}
                  originalOrderItem={orderItem}
                />
              </DesktopTable.Cell>
            )}
          </>
        ) : (
          <>
            <DesktopTable.Cell border='none' width={'90px'}>
              <Typography>
                {`${orderItem?.pricePerPcs} ${intl.formatMessage({
                  id: 'currency.uah',
                })}`}
              </Typography>
            </DesktopTable.Cell>
            <DesktopTable.Cell border='none' marginBottom={'90px'} textAlign={'center'}>
              {orderItem?.quantity}
            </DesktopTable.Cell>
            {hasItemConditions && (
              <DesktopTable.Cell
                border='none'
                padding={'0 5px'}
                verticalAlign={showLocation ? 'bottom' : undefined}
                width={'90px'}
                textAlign={'center'}
              >
                <Flex direction='column'>
                  <ItemConditionField
                    index={index}
                    availableConditions={orderItem?.product?.avaliableConditions}
                    condition={orderItem?.availability?.condition}
                    readOnly={isStatusReserved}
                    setShowLocation={setShowLocation}
                    showLocation={showLocation}
                  />
                </Flex>
              </DesktopTable.Cell>
            )}
          </>
        )}
        <DesktopTable.Cell border='none' width={'90px'} textAlign={'center'}>
          <Typography>
            {`${(orderItem?.pricePerPcs * orderItem?.quantity || 0).toFixed(
              2,
            )} ${intl.formatMessage({
              id: 'currency.uah',
            })}`}
          </Typography>
        </DesktopTable.Cell>
        <DesktopTable.Cell border='none' textAlign={'right'} width={'40px'}>
          <MaterialIcons onPress={onDelete} name='delete' size={25} color='#BDBDBD' />
        </DesktopTable.Cell>
      </DesktopTable.Row>

      <DesktopTable.Row>
        <DesktopTable.Cell padding={0}>
          <></>
        </DesktopTable.Cell>
        <DesktopTable.Cell padding={0} colSpan={5}>
          {showLocation && orderItem?.place ? (
            <ItemLocation
              location={orderItem?.place}
              condition={orderItem?.availability?.condition}
            />
          ) : (
            <></>
          )}
        </DesktopTable.Cell>
      </DesktopTable.Row>
    </>
  );
};

export default OrderItem;

OrderItem.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  currentStatus: PropTypes.object,
  orderItem: PropTypes.object,
  hasItemConditions: PropTypes.bool,
  isStatusReserved: PropTypes.bool,
};
