import { endOfDay, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { Flex } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  DatePicker,
  MultiSelect,
  RequestHandler,
  SearchSelectInFilter,
} from '../../../components';
import { IS_MOBILE_SCREEN } from '../../../constants';
import { useFiltersContext } from '../../../context';
import { useFilter, useOrdersFilterQuery } from '../../../hooks';
import { getFilterOption, getName } from '../../../utils';
import styles from './styles';

export const OrdersFilters = () => {
  const { filters, setFilters, clearFilters } = useFilter();
  const { data, ...queryProps } = useOrdersFilterQuery();
  const { toggleFilter } = useFiltersContext();

  const intl = useIntl();

  const onSubmit = (values) => {
    setFilters({
      manager: getFilterOption(values.manager),
      customer: getFilterOption(values.customer),
      source: getFilterOption(values.source),
      payment: getFilterOption(values.payment),
      status: getFilterOption(values.status),
      dateFrom: values?.dateFrom ? startOfDay(new Date(values?.dateFrom)) : '',
      dateTo: values?.dateTo ? endOfDay(new Date(values?.dateTo)) : '',
    });

    if (IS_MOBILE_SCREEN) {
      toggleFilter();
    }
  };

  const getSelectOptions = ({ name, label = 'name', isFullName = false }) => {
    if (!data?.[name]?.length) return [];
    return data?.[name]?.map((el) => ({
      value: el.id,
      label: isFullName ? getName(el?.firstName, el?.lastName) : el[label],
    }));
  };

  const getInitialValue = ({ value, name, label = 'name', isFullName = false }) => {
    if (!data?.[name]?.length || !value) return [];

    // const list = value?.split(',');
    const list = value.length > 0 ? [...value] : [value];

    return data?.[name]
      ?.filter((el) => list?.includes(Number(el.id)))
      ?.map((el) => ({
        value: el.id,
        label: isFullName ? getName(el?.firstName, el?.lastName) : el[label],
      }));
  };

  const handleResetForm = (setFieldValue) => {
    setFieldValue('manager', []);
    setFieldValue('customer', []);
    setFieldValue('source', []);
    setFieldValue('payment', []);
    setFieldValue('status', []);
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');
    clearFilters();
  };

  const initialValues = {
    manager: getInitialValue({
      value: filters?.manager,
      name: 'users',
      isFullName: true,
    }),
    customer: getInitialValue({
      value: filters?.customer,
      name: 'customers',
      isFullName: true,
    }),
    source: getInitialValue({
      value: filters?.source,
      name: 'sources',
    }),
    payment: getInitialValue({
      value: filters?.payment,
      name: 'paymentStatuses',
    }),
    status: getInitialValue({
      value: filters?.status,
      name: 'statuses',
    }),
    dateFrom: filters?.dateFrom || '',
    dateTo: filters?.dateTo || '',
  };

  return (
    <RequestHandler {...queryProps}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, handleReset, setFieldValue }) => (
          <>
            <MultiSelect
              name='status'
              placeholder={intl.formatMessage({ id: 'app.status' })}
              options={getSelectOptions({ name: 'statuses' })}
              mb='md'
            />
            <Flex justify='space-between' mb='md'>
              <DatePicker
                name='dateFrom'
                label={intl.formatMessage({ id: 'date.from' })}
                width='48%'
                inputStyles={styles.input}
              />
              <DatePicker
                name='dateTo'
                label={intl.formatMessage({ id: 'date.to' })}
                width='48%'
                inputStyles={styles.input}
              />
            </Flex>
            <MultiSelect
              name='manager'
              placeholder={intl.formatMessage({ id: 'app.manager' })}
              options={getSelectOptions({ name: 'users', isFullName: true })}
              mb='md'
            />

            <SearchSelectInFilter
              name='customer'
              placeholder='app.customer'
              options={getSelectOptions({ name: 'customers', isFullName: true })}
              mb='md'
            />
            <MultiSelect
              name='source'
              placeholder={intl.formatMessage({ id: 'app.source' })}
              options={getSelectOptions({ name: 'sources' })}
              mb='md'
            />
            <MultiSelect
              name='payment'
              placeholder={intl.formatMessage({ id: 'app.payment' })}
              options={getSelectOptions({ name: 'paymentStatuses' })}
              mb='md'
            />
            <Flex justify='space-between' mt={6}>
              <Button
                onPress={() => {
                  handleReset();
                  handleResetForm(setFieldValue);
                }}
                variant='secondary'
                intlId='app.clear'
                size='45%'
                height={8}
              />
              <Button onPress={handleSubmit} intlId='app.apply' size='45%' height={8} />
            </Flex>
          </>
        )}
      </Formik>
    </RequestHandler>
  );
};
