export const colors = {
  primary: { default: '#47C8FF' },
  secondary: '#2C3E61',
  gray: {
    extraLight: '#f2f2f2',
    light: '#F7F7F7',
    main: '#828282',
    dark: '#BDBDBD',
    default: '#C4C4C4',
    darkness: '#373737',
    light2: '#d9d9d9',
    background: 'rgba(238, 238, 238, 0.5)',
    border: '#E0E0E0',
  },
  text: { default: '#4F4F4F' },
  dark: '#222222',
  danger: '#EB5757',
  error: '#dc3545',
  //rose only for colorScheme when need error color
  rose: { default: '#dc3545' },
  warning: '#F2994A',
  success: '#6FCF97',
  white: '#ffffff',
  pink: '#CE5DAE',
  black: '#000000',
  blue: {
    light: '#54A0FF',
    dark: '#00D2D3',
  },
  purple: {
    dark: '#764ACE',
  },
  yellow: {
    dark: '#FDCA7E',
  },
  green: '#8BC34A',
  red: '#EE8585',
};
